import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";

const csvHeaders = [
  "kode_tahun_ajaran",
  "kode_sekolah",
  "kode_kelas",
  "nis",
  "cekal",
];

interface ICsvData {
  kode_tahun_ajaran: string;
  kode_sekolah: string;
  kode_kelas: string;
  nis: string;
  cekal: string;
}

const SchoolYearStudentUpload = () => {
  const verifyData = async (csvData: ICsvData[]) => {

    const normalizedData = csvData.map((row: ICsvData) => {
      return {
        ...row,
        nis: row.nis.replace(/\s/g, "").replace(/"/g, ""),
      }
    });

    const schoolYearData = await request.getList({
      key: "kode_tahun_ajaran",
      csv: normalizedData,
      apiResource: entity.SCHOOL_YEAR.NAME,
      entityProp: "id",
    });

    const schoolData = await request.getList({
      key: "kode_sekolah",
      csv: normalizedData,
      apiResource: entity.STAGE.NAME,
      entityProp: "id",
    });

    const gradeData = await request.getList({
      key: "kode_kelas",
      csv: normalizedData,
      apiResource: entity.GRADE.NAME,
      entityProp: "code",
    });

    const studentData = await request.getList({
      key: "nis",
      csv: normalizedData,
      apiResource: entity.STUDENT.NAME,
      entityProp: "nis",
    });

    return normalizedData.map((row: ICsvData) => {
      const { kode_tahun_ajaran, kode_sekolah, kode_kelas, nis, cekal } = row;
      const schoolYear = schoolYearData
        ? schoolYearData.find((d: any) => +d.id === +kode_tahun_ajaran)
        : undefined;
      const stage = schoolData
        ? schoolData.find((d: any) => +d.id === +kode_sekolah)
        : undefined;
      const grade = gradeData
        ? gradeData.find((d: any) => d.code === kode_kelas)
        : undefined;
      const student = studentData
        ? studentData.find((d: any) => +d.nis === +nis)
        : undefined;

      if (schoolYear && stage && grade && student) {
        return {
          code: `${schoolYear.id}${student.id}`,
          schoolYearId: schoolYear.id,
          stageId: stage.id,
          gradeId: grade.id,
          studentId: student.id,
          ...(cekal && { isBlacklisted: +cekal }),
        };
      }
    });
  };

  return (
    <React.Fragment>
      <Title title="schoolYearStudentUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="schoolYearStudentUpload.fileInputLabel"
        apiResource={entity.SCHOOL_YEAR_STUDENT.API_UPLOAD}
        resource={entity.SCHOOL_YEAR_STUDENT.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default SchoolYearStudentUpload;
