import { Create } from "react-admin";
import Form from "./GradeForm";

const GradeCreate = () => {
  return (
    <Create redirect="list" title="resources.grades.form.create">
      <Form />
    </Create>
  );
};

export default GradeCreate;
