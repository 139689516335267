import { Edit, useGetRecordId, useGetOne } from "react-admin";
import Form from "./UserForm";
import entity from "@src/entityResource";

const UserEdit = () => {
  const recordId = useGetRecordId();
  const {data: user} = useGetOne(entity.USER.NAME, {id: recordId ?? 0});
  const title = user?.name ? user?.name : user?.student ? user?.student?.name : user?.employee?.name;

  return(
    <Edit title={title}>
      <Form />
    </Edit>
  )
}

export default UserEdit;
