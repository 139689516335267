import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import list from "./SchoolYearList";
import create from "./SchoolYearCreate";
import edit from "./SchoolYearEdit";
import upload from "./SchoolYearUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: CalendarMonthIcon,
};
