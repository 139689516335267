import PaymentsIcon from "@mui/icons-material/Payments";
import list from "./SchoolYearInvoiceList";
import upload from "./SchoolYearInvoiceUpload";
import generate from "./SchoolYearInvoiceGenerate";

export default {
  list: list,
  upload: upload,
  generate: generate,
  icon: PaymentsIcon,
};
