import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
  useLocaleState,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { listRowSx } from "@src/themes/componentStyle";
import entity from "@src/entityResource";
import { displayFormat } from "@src/helper/dateFormat";

const SchoolYearList = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-years.filters.search"
      uploadUrl={entity.SCHOOL_YEAR.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <>
              <div>{translate("resources.school-years.fields.id")}&nbsp;<strong>{record.id}</strong></div>
              <div>{translate("resources.school-years.fields.name")}&nbsp;<strong>{record.name}</strong></div>
            </>
          )}
          secondaryText={(record) =>
            `Periode ${displayFormat(record.startDate, locale)} - ${displayFormat(
              record.endDate,
              locale
            )}`
          }
          rightAvatar={(record) =>
            record.isCurrent === true ? (
              <RadioButtonCheckedIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <FunctionField
            label="Periode"
            render={(record: any) =>
              `${displayFormat(record.startDate, locale)} - ${displayFormat(
                record.endDate,
                locale
              )}`
            }
          />
          <FunctionField
            label="resources.school-years.fields.isCurrent"
            render={(record: any) =>
              record.isCurrent === true ? <CheckIcon /> : null
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolYearList;
