import { Create } from 'react-admin';
import Form from './SchoolYearStudentNoteForm';

const SchoolYearStudentNoteCreate = () => (
  <Create
    redirect="list"
    title="resources.school-year-student-notes.form.create"
  >
    <Form />
  </Create>
);

export default SchoolYearStudentNoteCreate;
