import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import list from "./SchoolYearEmployeeList";
import create from "./SchoolYearEmployeeCreate";
import edit from "./SchoolYearEmployeeEdit";
import upload from "./SchoolYearEmployeeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: SupervisorAccountIcon,
};
