import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import list from "./NoteTypeList";
import create from "./NoteTypeCreate";
import edit from "./NoteTypeEdit";
import upload from "./NoteTypeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: LegendToggleIcon,
};
