import { Edit } from "react-admin";
import Form from "./GenerationForm";
import EditTitle from "@src/component/EditTitle";

const GenerationEdit = () => (
    <Edit title={<EditTitle />}>
        <Form />
    </Edit>
);

export default GenerationEdit;
