import React from "react";
const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);
import {
  AutocompleteInput,
  AutocompleteInputProps,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageInput,
  ImageField,
  TextInput,
  useGetList,
  useTranslate,
  required,
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import { Box, Grid, Typography } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxContainerFieldsetSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import entity from "@src/entityResource";
import { longDisplayFormat } from "@src/helper/dateFormat";

const HighlightType = () => {
  const { setValue } = useFormContext();
  const { data: highlightTypes } = useGetList(entity.HIGHLIGHT_TYPE.NAME);

  const handleStageChange: AutocompleteInputProps['onChange'] = (value) => {
    const selected = highlightTypes?.find(highlight => highlight.id === value);
    const text = selected ? selected.name : '';
    setValue("highlightTypeName", text);
  };
  return (
    <React.Fragment>
      <AutocompleteInput
        source="highlightTypeId"
        choices={highlightTypes}
        optionText="name"
        optionValue="id"
        fullWidth
        validate={required()}
        onChange={handleStageChange}
      />
      <TextInput type="hidden" source="highlightTypeName" style={{ display: 'none' }} />
    </React.Fragment>
  );
}

const SchoolHighlightForm = () => {
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <FormDataConsumer>
                  {({ formData }) => (
                    <>
                      <Box flex={1} mr={boxItemMr}>
                        <DateInput
                          source="startDate"
                          validate={required()}
                          fullWidth
                          helperText={formData.startDate ? longDisplayFormat(formData.startDate, "id-ID") : ""}
                        />
                      </Box>
                      <Box flex={1} mr={boxItemMr}>
                        <DateInput source="endDate" validate={required()} fullWidth helperText={formData.endDate ? longDisplayFormat(formData.endDate, "id-ID") : ""} />
                      </Box>
                    </>
                  )}
                </FormDataConsumer>

              </Box>
            </Box>
            <Box display={hideWhiteSpace} flex={1} mr={boxItemMr}>&nbsp;
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <HighlightType />
            </Box>
            <Box display={hideWhiteSpace} flex={1} mr={boxItemMr}>&nbsp;
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Stage source="stageId" isRequired={false} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData }) => <Grade source="gradeId" formData={formData} isRequired={false} />}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="title"
                validate={required()}
                resettable={true}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <RichTextInput source='description' />
                </Box>
              </Box>
            </Box>

            <Box flex={1} mr={boxItemMr}>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <FileInput source="pdf" accept="application/pdf">
                  <FileField source="src" title="title" />
                </FileInput>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <Typography align="center" variant="subtitle1" gutterBottom>
                    <strong>{translate("amip.common.or")}</strong>
                  </Typography>
                </Box>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <ImageInput source="file" accept="image/*" maxSize={500000} fullWidth>
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Box>
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolHighlightForm;
