import React from "react";
import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
  useLocaleState,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { iFile } from "@src/component/iFile";
import PreviewFile from "@src/component/PreviewFile";
import FileViewer from "@src/component/FileViewer";
import entity from "@src/entityResource";
import { displayFormat } from "@src/helper/dateFormat";
import { listRowSxWithButton } from "@src/themes/componentStyle";

const SchoolHighlightList = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<iFile>();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const handleOpen = (r: any) => {
    setFile({
      title: r.gradeName ? r.stageName + " - " + r.gradeName : r.stageName,
      fileUrl: r.fileUrl,
      fileName: r.fileName
    });
    setOpen(true);
  };

  return (
    <SodikInfiniteList
      filterLabel="resources.school-highlights.filters.search"
      uploadUrl={entity.SCHOOL_HIGHLIGHT.UPLOAD_URL}
    >
      {isSmall ? (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <SimpleList
            primaryText={(record) => (
              <>
                <div style={{display: 'flex'}}>
                  <div><strong>{record.title}</strong></div>
                </div>
                <div style={{display: 'flex'}}>
                  <div>{record?.stage?.name}</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div>{record?.grade?.alphanumeric} {record?.grade?.sub} {record?.grade?.name}</div>
                </div>
              </>
            )}
            secondaryText={(record) => (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1}}>{displayFormat(record.startDate, locale)} - {displayFormat(record.endDate,locale)}</div>
                </div>
                <div style={{ display: 'flex', marginTop: 8}}>
                  <div style={{ flex: 1, border: 1, marginRight: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
                    </div>
                  </div>
                  <div style={{ flex: 1, border: 1, marginLeft: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <EditButton />
                    </div>
                  </div>
                </div>
              </>
            )}
            linkType={false}
            rowSx={listRowSxWithButton}
          />
        </>
        
      ) : (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <Datagrid>
            <TextField source="highlightType.name" />
            <TextField source="stage.name" />
            <TextField source="grade.name" />
            <TextField source="title" />
            <FunctionField
              label="Periode"
              render={(record: any) =>
                `${displayFormat(record.startDate, locale)} - ${displayFormat(
                  record.endDate,
                  locale
                )}`
              }
            />
            <>
              <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
              <EditButton />
            </>
          </Datagrid>
        </>       
      )}
    </SodikInfiniteList>
  );
};

export default SchoolHighlightList;
