import { Create } from 'react-admin';
import Form from './StudentReportForm';

const SchoolYearStudentNoteCreate = () => (
  <Create redirect="list" title="resources.student-reports.form.create">
    <Form />
  </Create>
);

export default SchoolYearStudentNoteCreate;
