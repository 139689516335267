import { Create } from "react-admin";
import Form from "./EmployeeForm";

const EmployeeCreate = () => {
  return (
    <Create redirect="list" title="resources.employees.form.create">
      <Form />
    </Create>
  );
};

export default EmployeeCreate;
