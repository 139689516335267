import SchoolIcon from "@mui/icons-material/School";
import list from "./StageList";
import create from "./StageCreate";
import edit from "./StageEdit";
import upload from "./StageUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: SchoolIcon,
};
