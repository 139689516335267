import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import list from "./RewardList";
import create from "./RewardCreate";
import edit from "./RewardEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: CardGiftcardIcon,
};
