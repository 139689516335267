import { Create } from "react-admin";
import Form from "./SchoolYearStudentForm";

const SchoolYearStudentCreate = () => (
  <Create redirect="list" title="resources.school-year-students.form.create">
    <Form />
  </Create>
);

export default SchoolYearStudentCreate;
