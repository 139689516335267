import GradeIcon from '@mui/icons-material/Grade';
import list from "./RewardTypeList";
import create from "./RewardTypeCreate";
import edit from "./RewardTypeEdit";
import upload from "./RewardTypeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: GradeIcon,
};
