import { Create } from "react-admin";
import Form from "./SchoolGuidelineForm";

const SchoolGuidelineCreate = () => (
  <Create redirect="list" title="resources.school-guidelines.form.create">
    <Form />
  </Create>
);

export default SchoolGuidelineCreate;
