import { Create } from "react-admin";
import Form from "./SchoolScheduleForm";

const SchoolScheduleCreate = () => (
  <Create redirect="list" title="resources.school-schedules.form.create">
    <Form />
  </Create>
);

export default SchoolScheduleCreate;
