import RuleIcon from '@mui/icons-material/Rule';
import list from "./SchoolGuidelineList";
import create from "./SchoolGuidelineCreate";
import edit from "./SchoolGuidelineEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: RuleIcon,
};
