import { TranslationMessages } from 'react-admin';
import raIndonesia from 'ra-language-indonesian-new';

const indonesiaMessages: TranslationMessages = {
  ...raIndonesia,
  amip: {
    search: 'Cari',
    configuration: 'Konfigurasi',
    language: 'Bahasa',
    common: {
      or: 'atau',
      view: 'Lihat',
    },
    theme: {
      name: 'Tema',
      light: 'Terang',
      dark: 'Gelap',
    },
    menu: {
      data: 'Data',
      education: 'Pendidikan',
      admin: 'Aplikasi',
      invoice: 'Tagihan',
      saving: 'Tabungan',
      reward: 'Bonus',
    },
  },
  resources: {
    login: {
      reset: 'Reset Sandi',
    },
    'reset-password': {
      fields: {
        identity: 'Nama Pengguna',
        pass: 'Sandi',
        passConf: 'Konfirmasi Sandi',
        code: 'Kode Verifikasi',
        reset: 'Reset Sandi',
        login: 'Halaman Masuk',
        success: 'Kode verifikasi telah di kirim melalui email',
        error: 'Email tidak terdaftar',
        changeSuccess: 'Sandi berhasil diubah',
        changeError: 'Kode verifikasi salah',
      },
    },
    stages: {
      name: 'Sekolah',
      fields: {
        id: 'Kode Sekolah',
        name: 'Nama Sekolah',
        phone: 'Telpon Sekolah',
        email: 'Email Sekolah',
        whatsapp: 'Whatsapp Sekolah',
      },
      form: {
        create: 'Buat Data Sekolah Baru',
      },
      filters: {
        search: 'Pencarian Data Sekolah',
      },
    },
    grades: {
      name: 'Kelas',
      fields: {
        stageId: 'Nama Sekolah',
        code: 'Kode Kelas',
        classCode: 'Inisial Kelas',
        name: 'Nama Kelas',
        'stage.name': 'Nama Sekolah',
        stageName: 'Nama Sekolah',
        alphanumeric: 'Huruf/ Angka',
        sub: 'Sub Huruf/ Angka',
      },
      form: {
        create: 'Tambah Data Kelas Baru',
      },
      filters: {
        search: 'Pencarian Data Kelas',
      },
    },
    'school-years': {
      name: 'Tahun Ajaran',
      fields: {
        id: 'Kode Tahun Ajar',
        name: 'Deskripsi',
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Akhir',
        isCurrent: 'Aktif',
      },
      form: {
        create: 'Tambah Tahun Ajaran Baru',
      },
      filters: {
        search: 'Pencarian Tahun Ajaran',
      },
    },
    generations: {
      name: 'Angkatan',
      fields: {
        code: 'Kode Angkatan',
        name: 'Nama Angkatan',
      },
      form: {
        create: 'Tambah Angkatan Baru',
      },
      filters: {
        search: 'Pencarian Angkatan',
      },
    },
    employees: {
      name: 'Guru',
      fields: {
        nik: 'NIK',
        code: 'NIP',
        name: 'Nama',
        nickname: 'Nama Panggilan',
        communicationPhoneNo: 'Telp',
        communicationEmail: 'Email',
        address: 'Alamat',
        longlat: 'Longitude, Latitude',
        avatar: 'Avatar',
      },
      form: {
        create: 'Tambah Data Guru',
      },
      filters: {
        search: 'Pencarian Data Guru',
      },
    },
    students: {
      name: 'Siswa',
      tabs: {
        student: 'Siswa',
        father: 'Ayah',
        mother: 'Ibu',
        communication: 'Kontak',
        address: 'Alamat',
      },
      fields: {
        generationId: 'Angkatan',
        'generation.name': 'Angkatan',
        nik: 'NIK',
        nis: 'NIS',
        name: 'Nama',
        nickname: 'Nama Panggilan',
        previousSchool: 'Asal Sekolah',
        communicationPhoneNo: 'Telp',
        communicationEmail: 'Email',
        familyCardNo: 'No Kartu Keluarga',
        fatherIdNo: 'NIK Ayah',
        fatherName: 'Nama Ayah',
        fatherPhoneNo: 'Telp Ayah',
        fatherEmail: 'Email Ayah',
        fatherOccupation: 'Pekerjaan Ayah',
        fatherSalary: 'Gaji Ayah',
        motherIdNo: 'NIK Ibu',
        motherName: 'Nama Ibu',
        motherPhoneNo: 'Telp Ibu',
        motherEmail: 'Email Ibu',
        motherOccupation: 'Pekerjaan Ibu',
        address: 'Alamat',
        longlat: 'Longitude, Latitude',
        avatar: 'Avatar',
      },
      form: {
        create: 'Tambah Siswa Baru',
      },
      filters: {
        search: 'Pencarian Data Siswa',
      },
    },
    'invoice-types': {
      name: 'Kategori Tagihan',
      fields: {
        generationId: 'Angkatan',
        'generation.name': 'Angkatan',
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        id: 'Kode Tagihan',
        name: 'Nama Tagihan',
        isMonthly: 'Bulanan',
        isYearly: 'Tahunan',
        amount: 'Jumlah Tagihan',
        category: 'Kategori Tagihan',
      },
      form: {
        create: 'Kategori Tagihan Baru',
      },
      filters: {
        search: 'Pencarian Kategori Tagihan',
      },
    },
    'highlight-types': {
      name: 'Kategori Sorotan',
      fields: {
        id: 'Kode Sorotan',
        name: 'Nama Sorotan',
      },
      form: {
        create: 'Kategori Sorotan Baru',
      },
      filters: {
        search: 'Pencarian Kategori Sorotan',
      },
    },
    'note-types': {
      name: 'Kategori Perkembangan',
      fields: {
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        code: 'Kode',
        name: 'Nama',
      },
      form: {
        create: 'Kategori Perkembangan',
      },
      filters: {
        search: 'Pencarian Kategori Perkembangan',
      },
    },
    'school-calendars': {
      name: 'Kalender',
      fields: {
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        gradeId: 'Kelas',
        'grade.name': 'Kelas',
        name: 'Kegiatan',
        description: 'Keterangan',
        gcalInvite: 'Undangan Google Calendar',
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Selesai',
      },
      form: {
        create: 'Tambah Kalender Sekolah',
      },
      filters: {
        search: 'Pencarian Kalender Sekolah',
      },
    },
    'school-year-students': {
      name: 'Peserta Didik',
      fields: {
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        'student.generation.code': 'Angkatan',
        stageId: 'Nama Sekolah',
        'stage.name': 'Nama Sekolah',
        gradeId: 'Nama Kelas',
        gradeCode: 'Inisial Kelas',
        'grade.name': 'Nama Kelas',
        studentId: 'Nama Siswa',
        'student.name': 'Nama Siswa',
        isBlacklisted: 'Cekal',
        schoolYearInvoices: 'Tagihan',
        invoicePeriode: 'Periode',
        invoiceTypeId: 'Kategori',
        invoicePayment: 'Pembayaran',
        invoiceAmount: 'Jumlah Tagihan',
        invoiceBalance: 'Sisa Tagihan',
      },
      form: {
        create: 'Peserta Didik Baru',
      },
      filters: {
        search: 'Pencarian Data Peserta Didik',
      },
    },
    'school-year-invoices': {
      name: 'Tagihan',
      fields: {
        'schoolYearStudent.schoolYear.name': 'Tahun Ajaran',
        'schoolYearStudent.stage.name': 'Sekolah',
        'schoolYearStudent.grade.name': 'Kelas',
        'schoolYearStudent.student.name': 'Siswa',
        invoicePeriode: 'Periode',
        'invoiceType.name': 'Kategori',
        invoiceAmount: 'Jumlah Tagihan',
        invoicePayment: 'Pembayaran',
        invoiceBalance: 'Sisa Tagihan',
        invoiceTypeId: 'Kategori',
        studentId: 'Peserta Didik',
      },
      form: {
        create: 'Data Tagihan Baru',
        generate: 'Data Tagihan Baru',
        periode: 'Periode',
      },
      filters: {
        search: 'Pencarian Data Tagihan',
      },
      list: {
        amount: 'Tagihan',
        balance: 'Sisa',
      },
    },
    'school-year-employees': {
      name: 'Guru Kelas',
      fields: {
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        gradeId: 'Kelas',
        gradeCode: 'Insial Kelas',
        'grade.name': 'Nama Kelas',
        employeeId: 'Guru',
        'employee.name': 'Guru Kelas',
      },
      form: {
        create: 'Tambah Guru Kelas',
      },
      filters: {
        search: 'Pencarian Data Guru Kelas',
      },
    },
    'school-highlights': {
      name: 'Sorotan Sekolah',
      fields: {
        'highlightType.name': 'Kategori',
        nama: 'Nama Kegiatan',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        highlightTypeId: 'Kategori',
        gradeId: 'Kelas',
        'grade.name': 'Kelas',
        title: 'Nama Kegiatan',
        description: 'Deskripsi Kegiatan',
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Selesai',
        periode: 'Periode',
        file: 'Flyer Kegiatan (Ukuran Max 400Kb)',
      },
      filters: {
        search: 'Pencarian Kegiatan Sekolah',
      },
      form: {
        create: 'Kegiatan Baru',
      },
    },
    'school-schedules': {
      name: 'Jadwal Pelajaran',
      fields: {
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        name: 'Jadwal',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        gradeId: 'Kelas',
        'grade.name': 'Kelas',
        title: 'Jadwal',
        description: 'Jadwal Pelajaran',
        scheduleDate: 'Tanggal (Jadwal per hari)',
        startDate: 'Tanggal Awal (Jadwal per pekan. Periode awal)',
        endDate: 'Tanggal Selesai (Jadwal per pekan. Periode akhir)',
        periode: 'Periode',
        file: 'Flyer Jadwal Peljaran (Ukuran Max 400Kb)',
      },
      filters: {
        search: 'Pencarian Jadwal Pelajaran',
      },
      form: {
        create: 'Jadwal Pelajaran Baru',
      },
    },
    'school-guidelines': {
      name: 'Tata Tertib',
      fields: {
        schoolYearId: 'Tahun Ajaran',
        schoolYearName: 'Tahun Ajaran',
        'schoolYear.name': 'Tahun Ajaran',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        name: 'Nama Tata Tertib',
        description: 'Tata Tertib',
        view: 'Lihat',
      },
      filters: {
        search: 'Pencarian Tata Tertib',
      },
      form: {
        create: 'Tata Tertib Baru',
      },
    },
    'school-year-student-notes': {
      name: 'Perkembangan Siswa',
      fields: {
        studentYearId: 'Peserta Didik',
        'schoolYearStudent.stageId': 'Sekolah',
        'schoolYearStudent.stage.name': 'Sekolah',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        'schoolYearStudent.gradeId': 'Kelas',
        gradeId: 'Kelas',
        gradeCode: 'Kelas',
        startDate: 'Periode Awal',
        endDate: 'Periode Akhir',
        'schoolYearStudent.studentId': 'Siswa',
        schoolYearStudentId: 'Siswa',
        'student.name': 'Peserta Didik',
        'schoolYearStudent.student.name': 'Siswa',
        summary: 'Rangkuman',
        note: 'Catatan',
        noteDetails: 'Detail Catatan',
      },
      filters: {
        search: 'Pencarian Catatan Perkembangan',
      },
      form: {
        create: 'Catatan Perkembangan Baru',
      },
    },
    'student-reports': {
      name: 'Laporan Perkembangan',
      fields: {
        studentYearId: 'Peserta Didik',
        'schoolYearStudent.stageId': 'Sekolah',
        'schoolYearStudent.stage.name': 'Sekolah',
        stageId: 'Sekolah',
        'stage.name': 'Sekolah',
        'schoolYearStudent.gradeId': 'Kelas',
        gradeId: 'Kelas',
        gradeCode: 'Kelas',
        startDate: 'Periode Awal',
        endDate: 'Periode Akhir',
        'schoolYearStudent.studentId': 'Siswa',
        schoolYearStudentId: 'Siswa',
        'student.name': 'Peserta Didik',
        'schoolYearStudent.student.name': 'Siswa',
        summary: 'Rangkuman',
        note: 'Catatan',
        noteDetails: 'Detail Catatan',
      },
      filters: {
        search: 'Pencarian Catatan Perkembangan',
      },
      form: {
        create: 'Catatan Perkembangan Baru',
      },
    },
    users: {
      name: 'User',
      hint: {
        name: 'Masukan nama pengguna',
        student: 'Atau pilih siswa',
        employee: 'Atau pilih guru',
      },
      fields: {
        studentId: 'Siswa',
        employeeId: 'Guru',
        name: 'Nama',
        roleName: 'Peran',
        username: 'Nama Pengguna',
        password: 'Password',
        isActive: 'Aktif',
        email: 'Email',
        phoneNo: 'Whatsapp',
        currentStudent: 'Kelas',
        lastLogin: 'Login Terakhir',
        grade: 'Kelas',
      },
      filters: {
        search: 'Pencarian User',
      },
      form: {
        create: 'User Baru',
      },
    },
    roles: {
      name: 'Peran',
      fields: {
        code: 'Kode',
        name: 'Nama',
      },
      filters: {
        search: 'Pencarian Peran',
      },
      form: {
        create: 'Peran Baru',
      },
    },
    'reward-types': {
      name: 'Kategori Hadiah',
      fields: {
        id: 'Kode',
        name: 'Nama',
      },
      form: {
        create: 'Kategori Hadiah',
      },
      filters: {
        search: 'Pencarian Kategori Hadiah',
      },
    },
    rewards: {
      name: 'Hadiah',
      fields: {
        rewardTypeId: 'Kategori',
        rewardTypeName: 'Kategori',
        id: 'Kode',
        name: 'Nama',
        phoneNo: 'Whatsapp',
        map: 'Google Map',
        point: 'Poin',
        limit: 'Kuota',
        available: 'Tersedia',
        validFrom: 'Tanggal Berlaku',
        validTo: 'Tanggal Berakhir',
        value: 'Nilai Dalam Rp',
        description: 'Deskripsi',
        file: 'Flyer Hadiah (Ukuran Max 400Kb)',
      },
      form: {
        create: 'Data Hadiah Baru',
      },
      filters: {
        search: 'Pencarian Data Hadiah',
      },
      list: {
        point: 'Poin',
        available: 'Kuota',
        value: 'Nilai Dalam Rp',
      },
    },
    savings: {
      name: 'Tabungan',
      fields: {
        'student.schoolYearStudent[0].stage.name': 'Nama Sekolah',
        studentId: 'Nama Siswa',
        studentName: 'Nama Siswa',
        'student.name': 'Nama Siswa',
        'student.nis': 'NIS',
        nis: 'NIS',
        gradeCode: 'Inisial Kelas',
        grade: 'Nama Kelas',
        initialBalance: 'Saldo Awal',
        balance: 'Saldo',
        savingType: 'Jenis Transaksi',
        credit: 'Setoran',
        debit: 'Penarikan',
        amount: 'Jumlah',
        transaction: 'Transaksi',
        title: 'Transaksi Tabungan',
        description: 'Keterangan',
        previousBalance: 'Saldo Sebelumnya',
        createdAt: 'Tanggal Transaksi',
      },
      notifications: {
        saved: 'Data Tabungan berhasil disimpan',
        insuficient: 'Saldo tidak mencukupi',
      },
      form: {
        create: 'Tabungan Baru',
      },
      filters: {
        search: 'Pencarian Data Tabungan',
      },
      list: {
        balance: 'Saldo',
      },
    },
    components: {
      uploadLabel: 'Unggah',
      createLabel: 'Tambah',
      generateLabel: 'Tambah',
      generateAllLabel: 'Tambah Semua',
    },
  },
  form: {
    edit: 'Ubah',
  },
  stageUpload: {
    title: 'Unggah Data Sekolah',
    fileInputLabel: 'Unggah data sekolah',
  },
  gradeUpload: {
    title: 'Unggah Data Kelas',
    fileInputLabel: 'Unggah data kelas',
  },
  generationUpload: {
    title: 'Unggah Data Angkatan',
    fileInputLabel: 'Unggah data angkatan',
  },
  schoolYearUpload: {
    title: 'Unggah Data Tahun Ajaran',
    fileInputLabel: 'Unggah data tahun ajaran',
  },
  employeeUpload: {
    title: 'Unggah data guru',
    fileInputLabel: 'Unggah data guru',
  },
  studentUpload: {
    title: 'Unggah Data Siswa',
    fileInputLabel: 'Unggah data siswa',
  },
  invoiceTypeUpload: {
    title: 'Unggah Data Kategori Tagihan',
    fileInputLabel: 'Unggah data kategori tagihan',
  },
  schoolYearStudentUpload: {
    title: 'Unggah Data Peserta Didik',
    fileInputLabel: 'Unggah data peserta didik',
  },
  schoolYearEmployeeUpload: {
    title: 'Unggah Data Guru Kelas',
    fileInputLabel: 'Unggah data guru kelas',
  },
  schoolYearInvoiceUpload: {
    title: 'Unggah Data Tagihan',
    fileInputLabel: 'Unggah data Tagihan',
  },
  highlightTypeUpload: {
    title: 'Unggah Data Kategori Sorotan',
    fileInputLabel: 'Unggah data kategori sorotan',
  },
  schoolHighlightUpload: {
    title: 'Unggah Data Kegiatan',
    fileInputLabel: 'Unggah data kegiatan',
  },
  schoolScheduleUpload: {
    title: 'Unggah Data Jadwal',
    fileInputLabel: 'Unggah data jadwal',
  },
  schoolCalendarUpload: {
    title: 'Unggah Data Kalender',
    fileInputLabel: 'Unggah data kalender',
  },
  noteTypeUpload: {
    title: 'Unggah Kategori Perkembangan',
    fileInputLabel: 'Unggah kategori perkembangan',
  },
  userUpload: {
    title: 'Unggah Data User',
    fileInputLabel: 'Unggah data user',
  },
  roleUpload: {
    title: 'Unggah Data Peran',
    fileInputLabel: 'Unggah data peran',
  },
  rewardTypeUpload: {
    title: 'Unggah Kategori Bonus',
    fileInputLabel: 'Unggah kategori bonus',
  },
  savingUpload: {
    title: 'Unggah Transaksi Tabungan',
    fileInputLabel: 'Unggah transaksi tabungan',
  },
  csvUpload: {
    notifications: {
      error: 'File CSV tidak dapat di proses',
      success: 'Data CSV selesai di unggah',
    },
  },
};

export default indonesiaMessages;
