import { Edit } from "react-admin";
import Form from "./SchoolGuidelineForm";
import EditTitle from "@src/component/EditTitle";

const SchoolGuidelineEdit = () => (
  <Edit title={<EditTitle />}>
    <Form />
  </Edit>
);

export default SchoolGuidelineEdit;
