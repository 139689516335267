import { LoadingIndicator, LocalesMenuButton } from "react-admin";
import { Box } from "@mui/material";
import { ThemeSwapper } from "@src/themes/ThemeSwapper";

export const AppBarToolbar = () => (
  <>
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      <LocalesMenuButton />
      <ThemeSwapper />
    </Box>
    <LoadingIndicator />
  </>
);
