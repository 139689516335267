import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";
import { toInt, toDec } from "@src/helper/number";

interface ICsvData {
  angkatan: string;
  nik: string;
  nis: string;
  nisn: string;
  nama_siswa: string;
  jenis_kelamin: string;
  tempat_lahir: string;
  tanggal_lahir: string;
  asal_sekolah: string;
  avatar: string;
  no_kk: string;
  no_tlp: string;
  email: string;
  nik_ayah: string;
  nama_ayah: string;
  tahun_ayah: string;
  no_tlp_ayah: string;
  email_ayah: string;
  pekerjaan_ayah: string;
  gaji_ayah: string;
  nik_ibu: string;
  nama_ibu: string;
  tahun_ibu: string;
  no_tlp_ibu: string;
  email_ibu: string;
  pekerjaan_ibu: string;
  alamat: string;
  rt: string;
  rw: string;
  kecamatan: string;
  kabupaten: string;
  kode_pos: string;
  jarak_rumah: string;
  waktu_tempuh: string;
  longlat: string;
}

const headers = [
  "angkatan",
  "nik",
  "nis",
  "nisn",
  "nama_siswa",
  "jenis_kelamin",
  "tempat_lahir",
  "tanggal_lahir",
  "asal_sekolah",
  "avatar",
  "no_kk",
  "no_tlp",
  "email",
  "nik_ayah",
  "nama_ayah",
  "tahun_ayah",
  "no_tlp_ayah",
  "email_ayah",
  "pekerjaan_ayah",
  "gaji_ayah",
  "nik_ibu",
  "nama_ibu",
  "tahun_ibu",
  "no_tlp_ibu",
  "email_ibu",
  "pekerjaan_ibu",
  "alamat",
  "rt",
  "rw",
  "kecamatan",
  "kabupaten",
  "kode_pos",
  "jarak_rumah",
  "waktu_tempuh",
  "longlat"
];

const verifyData = async (csvData: ICsvData[]) => {

  const normalizedData = csvData.map((row: ICsvData) => {
    return {
      ...row,
      nik: row.nik.replace(/\s/g, "").replace(/"/g, ""),
      nis: row.nis.replace(/\s/g, "").replace(/"/g, ""),
      nisn: row.nisn.replace(/\s/g, "").replace(/"/g, ""),
      tanggal_lahir: row.tanggal_lahir.replace(/\s/g, "").replace(/"/g, ""),
      no_kk: row.no_kk.replace(/\s/g, "").replace(/"/g, ""),
      no_tlp: row.no_tlp.replace(/\s/g, "").replace(/"/g, ""),
      nik_ayah: row.nik_ayah.replace(/\s/g, "").replace(/"/g, ""),
      no_tlp_ayah: row.no_tlp_ayah.replace(/\s/g, "").replace(/"/g, ""),
      gaji_ayah: row.gaji_ayah.replace(/\s/g, "").replace(/"/g, ""),
      nik_ibu: row.nik_ibu.replace(/\s/g, "").replace(/"/g, ""),
      no_tlp_ibu: row.no_tlp_ibu.replace(/\s/g, "").replace(/"/g, ""),
    };
  })

  const generationData = await request.getList({
    key: "angkatan",
    csv: normalizedData,
    apiResource: entity.GENERATION.NAME,
    entityProp: "code",
  });

  return normalizedData.map((row: ICsvData) => {
    const {
      angkatan,
      nik,
      nis,
      nisn,
      nama_siswa,
      jenis_kelamin,
      tempat_lahir,
      tanggal_lahir,
      asal_sekolah,
      avatar,
      no_kk,
      no_tlp,
      email,
      nik_ayah,
      nama_ayah,
      tahun_ayah,
      no_tlp_ayah,
      email_ayah,
      pekerjaan_ayah,
      gaji_ayah,
      nik_ibu,
      nama_ibu,
      tahun_ibu,
      no_tlp_ibu,
      email_ibu,
      pekerjaan_ibu,
      alamat,
      rt,
      rw,
      kecamatan,
      kabupaten,
      kode_pos,
      jarak_rumah,
      waktu_tempuh,
      longlat,
    } = row;

    const gen = generationData
      ? generationData.find((d: any) => d.code === angkatan)
      : undefined;

    if (gen) {
      return {
        ...(angkatan && { generationId: gen.id }),
        ...(nik && { nik: nik }),
        ...(nis && { nis: nis }),
        ...(nisn && { nisn: nisn }),
        ...(nama_siswa && { name: nama_siswa }),
        ...(jenis_kelamin && { gender: jenis_kelamin }),
        ...(tempat_lahir && { birthPlace: tempat_lahir }),
        ...(tanggal_lahir && { birthDate: tanggal_lahir }),
        ...(asal_sekolah && { previousSchool: asal_sekolah }),
        ...(avatar && { avatar }),
        ...(no_kk && { familyCardNo: no_kk }),
        ...(no_tlp && { communicationPhoneNo: no_tlp }),
        ...(email && { communicationEmail: email }),
        ...(nik_ayah && { fatherIdNo: nik_ayah }),
        ...(nama_ayah && { fatherName: nama_ayah }),
        ...(tahun_ayah && { fatherYearOfBirth: tahun_ayah }),
        ...(no_tlp_ayah && { fatherPhoneNo: no_tlp_ayah }),
        ...(email_ayah && { fatherEmail: email_ayah }),
        ...(pekerjaan_ayah && { fatherJob: pekerjaan_ayah }),
        ...(gaji_ayah && { fatherSalary: gaji_ayah }),
        ...(nik_ibu && { motherIdNo: nik_ibu }),
        ...(nama_ibu && { motherName: nama_ibu }),
        ...(tahun_ibu && { motherYearOfBirth: tahun_ibu }),
        ...(no_tlp_ibu && { motherPhoneNo: no_tlp_ibu }),
        ...(email_ibu && { motherEmail: email_ibu }),
        ...(pekerjaan_ibu && { motherJob: pekerjaan_ibu }),
        ...(alamat && { address: alamat }),
        ...(rt && { rt }),
        ...(rw && { rw }),
        ...(kecamatan && { district: kecamatan }),
        ...(kabupaten && { city: kabupaten }),
        ...(kode_pos && { postalCode: kode_pos }),
        ...(jarak_rumah && { distance: toDec(jarak_rumah) }),
        ...(waktu_tempuh && { travelTime: waktu_tempuh }),
        ...(longlat && { longlat: longlat }),
      };
    }

    return;
  });
};

const StudentUpload = () => {
  return (
    <React.Fragment>
      <Title title="studentUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="studentUpload.fileInputLabel"
        apiResource={entity.STUDENT.API_UPLOAD}
        resource={entity.STUDENT.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default StudentUpload;
