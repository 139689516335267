import { Edit } from "react-admin";
import Form from "./RewardForm";

const RewardEdit = () => (
  <Edit>
    <Form />
  </Edit>
);

export default RewardEdit;
