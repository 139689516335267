import {
    Datagrid,
    SimpleList,
    TextField,
    FunctionField,
    EditButton,
    useLocaleState,
  } from "react-admin";
  import { useMediaQuery } from "@mui/material";
  import SodikInfiniteList from "@src/component/SodikInfiniteList";
  import { listRowSx } from "@src/themes/componentStyle";
  import { displayFormat } from "@src/helper/dateFormat";
  
  const ListEntities = () => {
    const [locale] = useLocaleState();
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  
    return (
      <SodikInfiniteList
        filterLabel="resources.school-year-student-notes.filters.search"
        uploadUrl={""}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => (
              <div>
                <div>{record?.schoolYearStudent?.student?.name}</div>
                <div style={{display: 'flex'}}>
                  <div>{record?.schoolYearStudent?.stage?.name}</div>
                  <div>&nbsp;-&nbsp;</div>
                  <div>{record?.schoolYearStudent?.grade?.alphanumeric}.{record?.schoolYearStudent?.grade?.sub} {record?.schoolYearStudent?.grade?.name}</div>
              </div>
              </div>
            )}
            secondaryText={(record) => (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1}}>{displayFormat(record.startDate, locale)} - {displayFormat(record.endDate,locale)}</div>
                </div>
              </>
            )}
            linkType={(record) => (record.canEdit ? "edit" : "show")}
            rowSx={listRowSx}
          />
        ) : (
          <Datagrid>
            <TextField source="schoolYearStudent.stage.name" />
            <FunctionField
              label="resources.school-year-student-notes.fields.gradeCode"
              render={(record: any) => `${record.schoolYearStudent.grade?.name} - ${record.schoolYearStudent.grade?.alphanumeric}.${record.schoolYearStudent.grade?.sub}`
              }
            />
            <TextField source="schoolYearStudent.student.name" />
            <FunctionField
              label="resources.school-year-student-notes.fields.startDate"
              render={(record: any) => displayFormat(record.startDate, locale)}
            />
            <FunctionField
              label="resources.school-year-student-notes.fields.endDate"
              render={(record: any) => displayFormat(record.endDate, locale)}
            />
            <EditButton />
          </Datagrid>
        )}
      </SodikInfiniteList>
    );
  };
  
  export default ListEntities;
  