import * as React from "react";
import { InfiniteList, ListProps, TextInput } from "react-admin";
import InfiniteLoad from "./InfiniteLoad";
import SodikTopToolbar from "./SodikTopToolbar";

interface ISodikInfiniteList extends ListProps {
  filterLabel: string;
  uploadUrl: string;
  generateUrl?: string;
  create?: boolean;
  onExport?: () => void;
  onGenerate?: () => void;
}

const SodikInfiniteList: React.FC<ISodikInfiniteList> = ({
  children,
  filterLabel,
  uploadUrl,
  generateUrl = undefined,
  create = true,
  onExport = undefined,
  onGenerate = undefined,
  //...rest
}) => {
  const textFilter = [
    <TextInput
      source="q"
      label={filterLabel}
      sx={{ debounce: 3000 }}
      alwaysOn
      resettable={true}
    />,
  ];

  return (
    <InfiniteList
      empty={false}
      pagination={<InfiniteLoad />}
      perPage={10}
      filters={textFilter}
      actions={<SodikTopToolbar upload={uploadUrl} create={create} generate={generateUrl} onExport={onExport} onGenerate={onGenerate} />}
      exporter={false}
    >
      {children}
    </InfiniteList>
  );
};

export default SodikInfiniteList;
