import { useInfinitePaginationContext } from "react-admin";
import { Box, Button } from "@mui/material";

const InfiniteLoad = () => {
  const { hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfinitePaginationContext();
  return hasNextPage ? (
    <Box mt={1} textAlign="center">
      <Button disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
        Load more
      </Button>
    </Box>
  ) : null;
};

export default InfiniteLoad;
