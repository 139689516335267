import { NumberFormatValues, NumericFormat } from 'react-number-format';

const TextFieldNumber = (props: any) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: NumberFormatValues) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  };
export default TextFieldNumber;