import env from "@src/environment";

const numberHelper = {
    /**
     * @description Parse value of string to number
     * @param v 
     * @returns 
    */
    toInt: (s: string) => isNaN(parseInt(s.replace(/[^0-9]/g, ""))) ? 0 : parseInt(s.replace(/[^0-9]/g, "")),

    /**
     * @description Parse value of string to decimal
     * @param s 
     * @returns 
     */
    toDec: (s:string) => {

        if (env.localFormat === 'id-ID') {
            // Replace thousand separator '.' with an empty string
            s = s.replace(/\./g, '');
            // Replace decimal separator ',' with '.'
            s = s.replace(',', '.');
        }

        return Number(s)
    },

    /**
     * @description Format number to locale
     * @param v 
     * @returns 
    */
    format: (n: number) => Intl.NumberFormat(env.localFormat).format(n)
}

export const { toInt, toDec, format } = numberHelper;
