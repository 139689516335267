import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";
import { removeSpace } from "@src/helper/string";

const headers = ["kode", "nama"];

interface ICsvData {
  kode: string;
  nama: string;
}

const verifyData = async (csvData: ICsvData[]) => {
  const roleData = await request.getList({
    key: "kode",
    csv: csvData,
    apiResource: entity.ROLE.NAME,
    entityProp: "code",
  });

  return csvData.map((row: ICsvData) => {
    const { kode, nama } = row;
    const role = roleData ? roleData.find((d: any) => d.code === kode) : undefined;

    return {
      code: role ? role.code : removeSpace(kode),
      name: nama,
    };
  });
};

const RoleUpload = () => {
  return (
    <React.Fragment>
      <Title title="roleUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="roleUpload.fileInputLabel"
        apiResource={entity.ROLE.API_UPLOAD}
        resource={entity.ROLE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default RoleUpload;
