import { Create } from "react-admin";
import Form from "./HighlightTypeForm";

const HighlightTypeCreate = () => {
  return (
    <Create redirect="list">
      <Form />
    </Create>
  );
};

export default HighlightTypeCreate;
