import Diversity3Icon from "@mui/icons-material/Diversity3";
import list from "./SchoolYearStudentList";
import create from "./SchoolYearStudentCreate";
import edit from "./SchoolYearStudentEdit";
import upload from "./SchoolYearStudentUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: Diversity3Icon,
};
