import { Edit } from "react-admin";
import Form from "./SchoolScheduleForm";

const SchoolScheduleEdit = () => (
  <Edit>
    <Form />
  </Edit>
);

export default SchoolScheduleEdit;
