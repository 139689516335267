import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";

const headers = ["nama"];

interface ICsvData {
  nama: string;
}

const verifyData = async (csvData: ICsvData[]) => {
  return csvData.map((row: ICsvData) => {
    const { nama} = row;
    return {
      ...(nama && { name: nama }),
    };
  });
};

const HighlightTypeUpload = () => {
  return (
    <React.Fragment>
      <Title title="highlightTypeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="highlightTypeUpload.fileInputLabel"
        apiResource={entity.HIGHLIGHT_TYPE.API_UPLOAD}
        resource={entity.HIGHLIGHT_TYPE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default HighlightTypeUpload;
