const componentStyle = {
  listRowSx: (record: any, index: number) => ({
    backgroundColor: index % 2 === 0 ? "#fff" : "#f0f0f0",
  }),
  listRowSxWithButton: (record: any, index: number) => ({
    backgroundColor: index % 2 === 0 ? "#fff" : "#f0f0f0",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingBottom: "1em"
  }),
  boxContainerDisplay: () => ({
    xs: "block",
    sm: "block",
    md: "flex",
  }),
  boxContainerSx: () => ({
    width: { xs: "100%", sm: "100%", md: "100%", lg: "80%" },
  }),
  boxContainerFieldsetSx: () => ({
    width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
  }),
  boxItemMr: () => ({
    xs: 0,
    sm: "0.5em",
  }),
  hideWhiteSpace: () => ({
    xs: "none", sm: "none", md: "block"
  })
};

export const { listRowSx, listRowSxWithButton, boxContainerDisplay, boxContainerSx, boxContainerFieldsetSx, boxItemMr, hideWhiteSpace } =
  componentStyle;
