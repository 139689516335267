import { Create } from "react-admin";
import Form from "./StudentForm";

const StudentCreate = () => {
  return (
    <Create redirect="list" title="resources.students.form.create">
      <Form />
    </Create>
  );
};

export default StudentCreate;
