import React from "react";
import { 
    Dialog,
    DialogTitle,
    DialogContent,
  } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();
import { iFile } from "./iFile";


const FileViewer = ({open, file, handleClose}: {open:boolean; file: iFile | undefined; handleClose:() => void}) => {
    const [numPages, setNumPages] = React.useState<number>();
    
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
            <DialogTitle>{file?.title}</DialogTitle>
            <DialogContent>
                {
                    file?.fileName.endsWith('.pdf') ? 
                        <Document file={file?.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <div key={`page_${index + 1}`} style={{ marginBottom: 10 }}>
                                    <Page pageNumber={index + 1} scale={0.9} />
                                </div>
                            ))}
                        </Document> : 
                        <img src={file?.fileUrl} alt={file?.fileName} style={{ width: '100%', height: 'auto' }} />
                }
                
            </DialogContent>
        </Dialog>
    )
}

export default FileViewer;