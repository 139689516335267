import { Create } from "react-admin";
import Form from "./SchoolYearEmployeeForm";

const SchoolYearEmployeeCreate = () => (
  <Create redirect="list" title="resources.school-year-employees.form.create">
    <Form />
  </Create>
);

export default SchoolYearEmployeeCreate;
