import { Edit } from "react-admin";
import Form from "./GradeForm";
import EditTitle from "@src/component/EditTitle";

const GradeEdit = () => {
  return (
    <Edit title={<EditTitle />}>
      <Form />
    </Edit>
  );
};

export default GradeEdit;
