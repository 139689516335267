import {
  Datagrid,
  SimpleList,
  TextField,
  FunctionField,
  EditButton,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { listRowSx } from "@src/themes/componentStyle";
import entity from "@src/entityResource";
import env from "@src/environment";

const InvoiceTypeList = () => {
  const translate = useTranslate();
  const intl = env.localFormat;
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <SodikInfiniteList
      filterLabel="resources.invoice-types.filters.search"
      uploadUrl={entity.INVOICE_TYPE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <div>
              <div>{record.name}&nbsp;{record.generation.name}&nbsp;{record.schoolYear.name}</div>
              <div>{translate("resources.invoice-types.fields.amount")}&nbsp;<strong>{Intl.NumberFormat(intl).format(record.amount)}</strong></div>
            </div>
          )}
          secondaryText={(record) => record.stage.name}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYear.name" />
          <TextField source="generation.name" />
          <TextField source="stage.name" />
          <TextField source="id" />
          <TextField source="name" />
          <FunctionField
            source="amount"
            textAlign="right"
            render={(record: any) =>
              Intl.NumberFormat(intl).format(record.amount)
            }
          />
          <FunctionField
            label="resources.invoice-types.fields.isMonthly"
            render={(record: any) =>
              record.isMonthly === true ? <CheckIcon /> : null
            }
          />
          <FunctionField
            label="resources.invoice-types.fields.isYearly"
            render={(record: any) =>
              record.isYearly === true ? <CheckIcon /> : null
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default InvoiceTypeList;
