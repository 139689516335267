import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";
import { getGrade } from "@src/helper/record";
import env from "@src/environment";

const getInvoiceAmount = (entities: any) => {
  if (entities) {
    return entities.reduce((acc: any, entity: any) => {
      return acc + entity.invoiceBalance;
    }, 0);
  }

  return 0;
}

const SchoolYearStudentList = () => {
  const intl = env.localFormat;
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-year-students.filters.search"
      uploadUrl={entity.SCHOOL_YEAR_STUDENT.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.student.name}
          secondaryText={(record: any) => (
            <>
              <div>{record?.stage?.name} {getGrade(record.grade)}</div>
              <div>{record?.student?.generation?.name} {record?.schoolYear?.name}</div>
            </>
          )}
          rightAvatar={(record) =>
            record.isBlacklisted === true ? (
              <DoDisturbOnIcon />
            ) : <CheckIcon />
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYear.name" />
          <TextField source="student.generation.code" />
          <TextField source="stage.name" />
          <FunctionField
            label="resources.school-year-students.fields.grade.name"
            render={(record: any) => getGrade(record.grade)}
          />
          <TextField source="student.name" />
          <FunctionField
            label="resources.school-year-students.fields.schoolYearInvoices"
            render={(record: any) => Intl.NumberFormat(intl).format(getInvoiceAmount(record?.schoolYearInvoices))}
          />
          <FunctionField
            label="resources.school-year-students.fields.isBlacklisted"
            render={(record: any) =>
              record.isBlacklisted === true ? <CheckIcon /> : null
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolYearStudentList;
