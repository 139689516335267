import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";

interface ICsvData {
  kode_sekolah: string;
  kode_kelas: string;
  nama_kelas: string;
  inisial_kelas: string;
  sub_kelas: string;
}

const headers = [
  "kode_sekolah",
  "kode_kelas",
  "nama_kelas",
  "inisial_kelas",
  "sub_kelas",
];

const verifyData = async (csvData: ICsvData[]) => {
  const schoolData = await request.getList({
    key: "kode_sekolah",
    csv: csvData,
    apiResource: entity.STAGE.NAME,
    entityProp: "id",
  });

  return csvData.map((row: ICsvData) => {
    const { kode_sekolah, kode_kelas, nama_kelas, inisial_kelas, sub_kelas } =
      row;
    const school = schoolData
      ? schoolData.find((d: any) => d.id === +kode_sekolah)
      : undefined;

    if (school) {
      return {
        ...(kode_sekolah && { stageId: school.id }),
        ...(kode_kelas && { code: kode_kelas }),
        ...(nama_kelas && { name: nama_kelas }),
        ...(inisial_kelas && { alphanumeric: inisial_kelas }),
        ...(sub_kelas && { sub: sub_kelas }),
      };
    }
  });
};

const GradeUpload = () => {
  return (
    <React.Fragment>
      <Title title="gradeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="gradeUpload.fileInputLabel"
        apiResource={entity.GRADE.API_UPLOAD}
        resource={entity.GRADE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default GradeUpload;
