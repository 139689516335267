import React from "react";
const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);
import { SimpleFormIterator, FormDataConsumer, AutocompleteInput, ArrayInput, DateInput, required, useGetList } from "react-admin";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import entity from "@src/entityResource";
import { longDisplayFormat } from "@src/helper/dateFormat";

const NoteType = ({ formData, getSource }: { formData: any, getSource: any }) => {
  const { data: noteTypes } = useGetList(entity.NOTE_TYPE.NAME, { filter: { q: '', stageId: formData?.schoolYearStudent?.stageId } });

  return (
    <AutocompleteInput
      source={getSource("noteTypeId")}
      choices={noteTypes}
      optionText="name"
      optionValue="id"
    />
  );
}

const StudentYear = ({ formData, getSource }: { formData: any, getSource: any }) => {
  const { data: students } = useGetList(entity.SCHOOL_YEAR_STUDENT.UNNOTED, {
    filter: {
      q: '',
      stageId: formData?.schoolYearStudent?.stageId,
      gradeId: formData?.schoolYearStudent?.gradeId,
      startDate: formData.startDate,
      endDate: formData.endDate
    }
  });

  return (
    <AutocompleteInput
      source={getSource("schoolYearStudentId")}
      choices={students}
      optionText="student.name"
      optionValue="id"
      validate={required()}
    />
  )
}

const SchoolYearStudentNoteForm = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <>
                    <Box flex={1} mr={boxItemMr}>
                      <DateInput
                        source="startDate"
                        validate={required()}
                        fullWidth
                        helperText={formData.startDate ? longDisplayFormat(formData.startDate, "id-ID") : ""}
                      />
                    </Box>
                    <Box flex={1} mr={boxItemMr}>
                      <DateInput source="endDate" validate={required()} fullWidth helperText={formData.endDate ? longDisplayFormat(formData.endDate, "id-ID") : ""} />
                    </Box>
                  </>
                )
              }}
            </FormDataConsumer>

          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Stage source="schoolYearStudent.stageId" isRequired={true} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData }) => <Grade source="schoolYearStudent.gradeId" formData={formData} isRequired={true} />}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData, getSource }) => <StudentYear formData={formData} getSource={getSource} />}
              </FormDataConsumer>
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <RichTextInput source="summary" />
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <ArrayInput source="noteDetails">
              <SimpleFormIterator
                disableReordering
                disableClear
              >
                <FormDataConsumer>
                  {({ formData, getSource }) => {
                    return (
                      <React.Fragment>
                        <NoteType formData={formData} getSource={getSource} />
                        <RichTextInput source={getSource('note')} />
                      </React.Fragment>
                    )
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  )
};

export default SchoolYearStudentNoteForm;
