import { Datagrid, TextField, EditButton, SimpleList, FunctionField, useCreate, useNotify, useRedirect, useTranslate } from "react-admin";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import { listRowSx } from "@src/themes/componentStyle";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { longDisplayFormatWithTime } from "@src/helper/dateFormat";
import { getStageAndGrade } from "@src/helper/record";
import ent from "@src/entityResource";

const getGrade = (record: any) => {
  if (record?.student?.schoolYearStudent[0]) {
    return getStageAndGrade(record?.student?.schoolYearStudent[0]);
  }

  if (record?.employee?.schoolYearEmployee[0]) {
    return getStageAndGrade(record?.employee?.schoolYearEmployee[0]);
  }

  return "";
}

const UserList = () => {
  const translate = useTranslate();
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const onGenerate = async () => {
    create(
      ent.USER.API_GENERATE,
      { data: {} },
      {
        onSuccess: () => {
          redirect("list", ent.USER.NAME);
          notify("csvUpload.notifications.success", { type: "info" });
        },
      }
    );
  }

  return (
    <SodikInfiniteList
      filterLabel="resources.users.filters.search"
      uploadUrl=""
      onGenerate={onGenerate}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: 1 }}>{translate("resources.users.fields.username")}&nbsp;<span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{record?.username}</span></div>
              <div style={{ flex: 1 }}>{getGrade(record)}</div>
            </div>
          )}
          secondaryText={(record) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(record?.student?.communicationEmail || record?.employee?.communicationEmail) && <div style={{ flex: 1 }}>{translate("resources.users.fields.email")}&nbsp;{record?.student?.communicationEmail || record?.employee?.communicationEmail}</div>}
              {(record?.student?.communicationPhoneNo || record?.employee?.communicationPhoneNo) && <div style={{ flex: 1 }}>{translate("resources.users.fields.phoneNo")}&nbsp;{record?.student?.communicationPhoneNo || record?.employee?.communicationPhoneNo}</div>}
              <div style={{ flex: 1 }}>{translate("resources.users.fields.lastLogin")},&nbsp;<strong>{record.lastLoginAt ? longDisplayFormatWithTime(record.lastLoginAt, "id-ID") : "-"}</strong></div>
            </div>
          )}
          rightAvatar={(record) =>
            record.isActive === true ? (
              <CheckIcon />
            ) : (
              <></>
            )
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <FunctionField
            label="resources.users.fields.name"
            render={(record: any) =>
              record.studentId ? (<TextField source="student.name" />) : record.employeeId ? (<TextField source="employee.name" />) : (<TextField source="name" />)
            }
          />
          <TextField source="username" />
          <TextField source="role.name" />
          <FunctionField
            label="resources.users.fields.currentStudent"
            render={(record: any) => getGrade(record)}
          />
          <FunctionField
            label="resources.users.fields.email"
            render={(record: any) =>
              record?.student?.communicationEmail ? record?.student?.communicationEmail : record?.employee?.communicationEmail ? record?.employee?.communicationEmail : ""
            }
          />
          <FunctionField
            label="resources.users.fields.phoneNo"
            render={(record: any) =>
              record?.student?.communicationPhoneNo ? record?.student?.communicationPhoneNo : record?.employee?.communicationPhoneNo ? record?.employee?.communicationPhoneNo : ""
            }
          />
          <FunctionField
            label="resources.users.fields.isActive"
            render={(record: any) =>
              record.isActive === true ? <CheckIcon /> : null
            }
          />
          <FunctionField
            label="resources.users.fields.lastLogin"
            render={(record: any) =>
              record.lastLoginAt ? longDisplayFormatWithTime(record.lastLoginAt, "id-ID") : "-"
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default UserList;
