import React from 'react';
import {
  AutocompleteInput,
  useGetList,
  required,
} from "react-admin";
import { useFormContext } from 'react-hook-form'
import entity from '@src/entityResource';

/**
 * @description SchoolYear component. Sy stand for SchoolYear
 * @returns 
 */
const Sy = () => {
  const { setValue } = useFormContext();
  const { data: schoolYears } = useGetList(entity.SCHOOL_YEAR.NAME, { filter: { isCurrent: true } });

  React.useEffect(() => {
    if (schoolYears && schoolYears.length === 1) {
      setValue('schoolYearId', schoolYears[0].id);
    }
  }, [schoolYears]);

  return (
    <React.Fragment>
      <AutocompleteInput
        source="schoolYearId"
        choices={schoolYears}
        optionText="name"
        optionValue="id"
        validate={required()}
        fullWidth
      />
    </React.Fragment>
  );
}

const SchoolYear = () => <Sy />

export default SchoolYear;