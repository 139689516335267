import { Create } from "react-admin";
import Form from "./SchoolCalenderForm";

const SchoolCalendarCreate = () => (
  <Create redirect="list" title="resources.school-calendars.form.create">
    <Form />
  </Create>
);

export default SchoolCalendarCreate;
