import React, { useState } from "react";
import {
  useCreate,
  useNotify,
  FileInput,
  FileField,
  SimpleForm,
  Toolbar,
  SaveButton,
  ListButton,
  useRedirect,
} from "react-admin";
import { parseCsv } from "@src/csvParser";

interface ICsvUpload {
  headers: string[];
  fileInputLabel: string;
  apiResource: string;
  resource: string;
  verifyData: (data: any) => any;
}

const CsvUpload: React.FC<ICsvUpload> = ({
  headers: csvHeaders,
  fileInputLabel,
  apiResource,
  resource,
  verifyData,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [csvData, setCsvData] = useState<any[]>();
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleFileChange = async (file: File) => {
    if (file) {
      const { data, errors } = await parseCsv(file, csvHeaders);
      try {
        if (errors.length === 0) {
          setCsvData(data);
          setDisabled(false);
        } else {
          notify(errors[0].message ? errors[0].message : "csvUpload.notifications.error", { type: "error" });
        }
      } catch (error) {
        notify("csvUpload.notifications.error", { type: "error" });
      }
    }
  };

  const onSave = async () => {
    const validData = await verifyData(csvData);
    setDisabled(true);
    if (validData) {
      create(
        apiResource,
        { data: validData },
        {
          onSuccess: () => {
            redirect("list", resource);
            notify("csvUpload.notifications.success", { type: "info" });
          },
        }
      );
    }
  };

  const UploadToolbar = () => (
    <Toolbar>
      <SaveButton disabled={disabled} variant="text" size="medium" />
      <ListButton resource={resource} variant="text" size="medium" />
    </Toolbar>
  );

  return (
    <SimpleForm toolbar={<UploadToolbar />} onSubmit={onSave}>
      <FileInput
        accept="application/csv, text/csv"
        source="attachments"
        label={fileInputLabel}
        onChange={handleFileChange}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  );
};

export default CsvUpload;
