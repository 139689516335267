import AddCardIcon from "@mui/icons-material/AddCard";
import list from "./InvoiceTypeList";
import create from "./InvoiceTypeCreate";
import edit from "./InvoiceTypeEdit";
import upload from "./InvoiceTypeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: AddCardIcon,
};
