import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import list from "./SchoolYearStudentNoteList";
import create from "./SchoolYearStudentNoteCreate";
import edit from "./SchoolYearStudentNoteEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: StackedBarChartIcon,
};
