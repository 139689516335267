import * as React from "react";
import { TopToolbar, CreateButton, ExportButton, Button } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from '@mui/icons-material/Add';
import ShortcutIcon from '@mui/icons-material/Shortcut';

const AdhocButton = ({ to, label, startIcon, onClick }: { to: string; label: string; startIcon: React.ReactNode; onClick?: () => void }) => {
  return (
    <Button
      component={RouterLink}
      to={to}
      label={label}
      startIcon={startIcon}
    />
  );
};

const SodikTopToolbar = ({ upload, create, generate, onExport, onGenerate }: { upload: string; create: boolean; generate?: string; onExport?: () => void; onGenerate?: () => void }) => {
  return (
    <TopToolbar>
      {upload && <AdhocButton label="resources.components.uploadLabel" to={upload} startIcon={<UploadFileIcon />} />}
      {generate && <AdhocButton label="resources.components.generateLabel" to={generate} startIcon={<AddIcon />} />}
      {create && <CreateButton label="resources.components.createLabel" />}
      {onExport && <ExportButton exporter={onExport} />}
      {onGenerate && <Button label="resources.components.generateAllLabel" onClick={onGenerate} startIcon={<ShortcutIcon />} />}
    </TopToolbar>
  );
};

export default SodikTopToolbar;
