import {useRecordContext} from "react-admin";
import {Button, ButtonProps} from "@mui/material";
import PageviewIcon from '@mui/icons-material/Pageview';

const PreviewFile = ({ onOpen, label, variant = "text" } : {onOpen: (r:any) => void; label: string; variant?:ButtonProps['variant']}) => {
    const record = useRecordContext();

    const handleClickOpen = () => {
        onOpen(record);
    };

    return (
        <>
            {record.fileUrl || record.imageUrl ? (
                <Button variant={variant} color="primary" onClick={handleClickOpen} startIcon={<PageviewIcon />}>
                    {label}
                </Button>
            ) : null}
        </>
    );
};

export default PreviewFile;