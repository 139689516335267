import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";
import { toInt } from "@src/helper/number";

const csvHeaders = [
  "nis",
  "tipe",
  "deskripsi",
  "kode",
  "jumlah"
];

interface ICsvData {
  nis: string;
  tipe: string;
  deskripsi: string;
  kode: string;
  jumlah: string;
}

const SavingUpload = () => {
  const verifyData = async (csvData: ICsvData[]) => {

    const normalizedData = csvData.map((row: ICsvData) => {
      return {
        ...row,
        kode: row.kode.replace(/\s/g, '').replace(/"/g, ''),
        nis: row.nis.replace(/\s/g, '').replace(/"/g, ''),
      }
    });

    const studentData = await request.getList({
      key: "nis",
      csv: normalizedData,
      apiResource: entity.STUDENT.NAME,
      entityProp: "nis",
    });

    return normalizedData.map((row: ICsvData) => {
      const { nis, tipe, deskripsi, kode, jumlah } = row;
      const student = studentData
        ? studentData.find((d: any) => +d.nis === +nis)
        : undefined;

      if (student) {
        return {
          studentId: student.id,
          savingType: tipe,
          code: kode,
          description: deskripsi,
          amount: toInt(jumlah),
        };
      }
    });
  };

  return (
    <React.Fragment>
      <Title title="savingUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="savingUpload.fileInputLabel"
        apiResource={entity.SAVING.API_UPLOAD}
        resource={entity.SAVING.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default SavingUpload;
