import { Create } from "react-admin";
import Form from "./SchoolHighlightForm";

const SchoolHighlightCreate = () => (
  <Create redirect="list" title="resources.school-highlights.form.create">
    <Form />
  </Create>
);

export default SchoolHighlightCreate;
