import CategoryIcon from "@mui/icons-material/Category";
import list from "./GradeList";
import create from "./GradeCreate";
import edit from "./GradeEdit";
import upload from "./GradeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: CategoryIcon,
};
