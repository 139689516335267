import { Edit } from "react-admin";
import Form from "./StageForm";
import EditTitle from "@src/component/EditTitle";

const StageEdit = () => (
  <Edit title={<EditTitle />}>
    <Form />
  </Edit>
);

export default StageEdit;
