import React from 'react';
import {
    AutocompleteInput,
    useGetList,
    required,
  } from "react-admin";
import entity from '@src/entityResource';

/**
 * @description Reward Type component. Rt stand for Reward Type
 * @returns 
 */
const Rt = ({isRequired}:{isRequired:boolean}) => {
    const { data: rewards } = useGetList(entity.REWARD_TYPE.NAME);

    return (
      <React.Fragment>
        <AutocompleteInput
          source="rewardTypeId"
          choices={rewards}
          optionText="name"
          optionValue="id"
          validate={isRequired ? required() : undefined}
          fullWidth
        />
      </React.Fragment>
    );
}

const RewardType = ({isRequired}:{isRequired:boolean}) => <Rt isRequired={isRequired} />
export default RewardType;
