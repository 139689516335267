import { Edit } from "react-admin";
import Form from "./SchoolHighlightForm";

const SchoolHighlightEdit = () => (
  <Edit>
    <Form />
  </Edit>
);

export default SchoolHighlightEdit;
