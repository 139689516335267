import { TextInput, required } from "react-admin";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import { Box, Grid } from "@mui/material";
import {
    boxContainerDisplay,
    boxContainerSx,
    boxItemMr,
} from "@src/themes/componentStyle";

const GenerationForm = () => (
    <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <SodikSimpleForm>
                <Box display={boxContainerDisplay} sx={boxContainerSx}>
                    <Box flex={1} mr={boxItemMr}>
                        <TextInput
                            source="code"
                            validate={required()}
                            resettable={true}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={boxItemMr}>
                        <TextInput
                            source="name"
                            validate={required()}
                            resettable={true}
                            fullWidth
                        />
                    </Box>
                </Box>
            </SodikSimpleForm>
        </Grid>
    </Grid>
);

export default GenerationForm;
