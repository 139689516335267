import * as React from "react";
const RichTextInput = React.lazy(() =>
  import("ra-input-rich-text").then((module) => ({
    default: module.RichTextInput,
  }))
);
import {
  FormDataConsumer,
  TextInput,
  DateInput,
  required,
} from "react-admin";
import { Box, Grid } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import SchoolYear from "@src/component/SchoolYear";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import { longDisplayFormat } from "@src/helper/dateFormat";

const SchoolCalendarForm = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <SchoolYear />
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>
              &nbsp;
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Stage source="stageId" isRequired={false} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData }) => {
                  return (
                    <Grade source="gradeId" formData={formData} isRequired={false} />
                  );
                }}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <>
                    <Box flex={1} mr={boxItemMr}>
                      <DateInput source="startDate" validate={required()} fullWidth helperText={formData.startDate ? longDisplayFormat(formData.startDate, "id-ID") : ""} />
                    </Box>
                    <Box flex={1} mr={boxItemMr}>
                      <DateInput source="endDate" validate={required()} fullWidth helperText={formData.endDate ? longDisplayFormat(formData.endDate, "id-ID") : ""} />
                    </Box>
                  </>
                );
              }}
            </FormDataConsumer>

          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="name"
                validate={required()}
                resettable={true}
                multiline
                fullWidth
              />
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>
              &nbsp;
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="gcalInvite"
                resettable={true}
                multiline
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <RichTextInput source="description" fullWidth />
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolCalendarForm;
