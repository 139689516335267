import KeyIcon from '@mui/icons-material/Key';
import list from "./RoleList";
import create from "./RoleCreate";
import edit from "./RoleEdit";
import upload from "./RoleUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: KeyIcon,
};
