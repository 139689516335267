import { TextInput, DateInput, BooleanInput, FormDataConsumer, required } from "react-admin";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
} from "@src/themes/componentStyle";
import { longDisplayFormat } from "@src/helper/dateFormat";

const SchoolYearForm = () => (
  <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <SodikSimpleForm>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={3} mr={boxItemMr}>
            <TextInput
              source="name"
              validate={required()}
              resettable={true}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <>
                  <Box flex={1} mr={boxItemMr}>
                    <DateInput source="startDate" validate={required()} fullWidth helperText={formData.startDate ? longDisplayFormat(formData.startDate, "id-ID") : ""} />
                  </Box>
                  <Box flex={1} mr={boxItemMr}>
                    <DateInput source="endDate" validate={required()} fullWidth helperText={formData.endDate ? longDisplayFormat(formData.endDate, "id-ID") : ""} />
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </Box>
        <BooleanInput source="isCurrent" validate={required()} />
      </SodikSimpleForm>
    </Grid>
  </Grid>
);

export default SchoolYearForm;
