import ContactPageIcon from "@mui/icons-material/ContactPage";
import list from "./StudentList";
import create from "./StudentCreate";
import edit from "./StudentEdit";
import upload from "./StudentUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: ContactPageIcon,
};
