import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";
import { toInt } from "@src/helper/number";

const csvHeaders = [
  "kode",
  "kode_tahun_ajaran",
  "nis",
  "kode_tagihan",
  "periode",
  "jumlah_tagihan",
  "pembayaran",
  "sisa_tagihan",
];

interface ICsvData {
  kode: string;
  kode_tahun_ajaran: string;
  nis: string;
  kode_tagihan: string;
  periode: string;
  jumlah_tagihan: string;
  pembayaran: string;
  sisa_tagihan: string;
}

const SchoolYearInvoiceUpload = () => {
  const verifyData = async (csvData: ICsvData[]) => {

    const normalizedData = csvData.map((row: ICsvData) => {
      return {
        ...row,
        kode: row.kode.replace(/\s/g, '').replace(/"/g, ''),
        nis: row.nis.replace(/\s/g, '').replace(/"/g, ''),
        periode: row.periode.replace(/\s/g, '').replace(/"/g, ''),
        jumlah_tagihan: row.jumlah_tagihan.replace(/\s/g, ''),
        pembayaran: row.pembayaran.replace(/\s/g, ''),
        sisa_tagihan: row.sisa_tagihan.replace(/\s/g, ''),
      }
    });

    // Get list of [school year] data based on [kode_tahun_ajaran] given in file
    const schoolYearData = await request.getList({
      key: "kode_tahun_ajaran",
      csv: normalizedData,
      apiResource: entity.SCHOOL_YEAR.NAME,
      entityProp: "id",
    });

    // Get list of [student] data based on [nik] given in file
    const studentData = await request.getList({
      key: "nis",
      csv: normalizedData,
      apiResource: entity.STUDENT.NAME,
      entityProp: "nis",
    });

    // Get list of [invoice type] data based on [kode_tagihan] given in file
    const invoiceTypeData = await request.getList({
      key: "kode_tagihan",
      csv: normalizedData,
      apiResource: entity.INVOICE_TYPE.NAME,
      entityProp: "id",
    });

    // Get list of [school year student] data based on [kode_tahun_ajaran] and [nik]
    const schoolYearStudentCodes = normalizedData.map((row: ICsvData) => {
      const { kode_tahun_ajaran, nis } = row;

      // Find [school year] data based on [kode_tahun_ajaran] given in file
      const schoolYear = schoolYearData.find((d: any) => +d.id === +kode_tahun_ajaran)

      // Find [student] data based on [nik] given in file
      const student = studentData.find((d: any) => d.nis === nis)

      if (schoolYear && student) {
        return { code: `${schoolYear.id}${student.id}` };
      }
    }).map((row) => {
      if (row) {
        return row;
      }
    });

    // Get list of [school year student] data based on [kode_tahun_ajaran] and [nik]
    const schoolYearStudentList = await request.getList({
      key: "code",
      csv: schoolYearStudentCodes.filter(
        (item): item is { code: string } => item !== undefined && typeof item.code === 'string'
      ),
      apiResource: entity.SCHOOL_YEAR_STUDENT.NAME,
      entityProp: "code",
    });

    // Prepare data for [school year invoice]
    const invoiceData = normalizedData
      .map((row: ICsvData) => {
        const {
          kode,
          kode_tahun_ajaran,
          nis,
          kode_tagihan,
          periode,
          jumlah_tagihan,
          pembayaran,
          sisa_tagihan,
        } = row;

        // Find [student] data based on [nik] given in file
        const student = studentData
          ? studentData.find((d: any) => d.nis === nis)
          : undefined;

        if (student) {
          // Find [school year student] data based on [kode_tahun_ajaran] and [student.id]
          const schoolYearStudent = schoolYearStudentList.find(
            (d: any) =>
              d.code === `${kode_tahun_ajaran}${student.id}`
          );

          if (schoolYearStudent) {
            // Find [invoice type] data based on [kode_tagihan] given in file
            const invoiceType = invoiceTypeData
              ? invoiceTypeData.find((d: any) => +d.id === +kode_tagihan)
              : undefined;

            if (invoiceType) {
              const invoiceAmount = toInt(jumlah_tagihan);
              const payment = toInt(pembayaran);
              const balance = invoiceAmount - payment;

              if ((balance !== invoiceAmount && balance > 0) || !kode) {
                const periodeCode = periode?.replace(/\s/g, '') ?? invoiceType?.id ?? '';
                return {
                  code: kode ? kode : `${schoolYearStudent.code}${invoiceType.id}${periodeCode}`,
                  schoolYearStudentId: schoolYearStudent.id,
                  invoicePeriode: periode,
                  invoiceTypeId: invoiceType.id,
                  invoiceAmount: toInt(jumlah_tagihan),
                  invoicePayment: payment,
                  invoiceBalance: balance,
                };
              }
            }
          }
        }
      })
      .filter((row) => row !== undefined);

    // Get list of paid [school year invoice] data based on [code]
    const paidInvoices = await request.getList({
      key: "code",
      csv: invoiceData,
      apiResource: entity.SCHOOL_YEAR_INVOICE.PAID,
      entityProp: "code",
    });

    // remove paidInvoice from invoiceData
    const invoices = invoiceData.filter(
      (invoice: any) =>
        !paidInvoices.find((paidInvoice: any) => paidInvoice.code === invoice.code)
    );

    return invoices;
  };

  return (
    <React.Fragment>
      <Title title="schoolYearInvoiceUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="schoolYearInvoiceUpload.fileInputLabel"
        apiResource={entity.SCHOOL_YEAR_INVOICE.API_UPLOAD}
        resource={entity.SCHOOL_YEAR_INVOICE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default SchoolYearInvoiceUpload;
