import { Show, useGetRecordId, useGetOne, useTranslate } from "react-admin";
import Form from "./SavingForm";
import entity from "@src/entityResource";
import { getGrade } from "@src/helper/record";

const SavingShow = () => {
  const translate = useTranslate();
  const recordId = useGetRecordId();
  const { data: saving } = useGetOne(entity.SAVING.NAME, { id: recordId ?? 0 });
  const { data: schoolYearStudent } = useGetOne(entity.SCHOOL_YEAR_STUDENT.STUDENT, { id: saving.studentId ?? 0 });
  const title = saving?.student && schoolYearStudent ? `${saving?.student.name} - ${getGrade(schoolYearStudent.grade)}` : translate('resources.savings.name');

  return (
    <Show title={title}>
      <Form />
    </Show>
  );
}

export default SavingShow;
