import { Edit } from "react-admin";
import Form from "./EmployeeForm";
import EditTitle from "@src/component/EditTitle";

const EmployeeEdit = () => {
  return (
    <Edit title={<EditTitle />}>
      <Form />
    </Edit>
  );
};

export default EmployeeEdit;
