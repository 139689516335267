import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import list from "./EmployeeList";
import create from "./EmployeeCreate";
import edit from "./EmployeeEdit";
import upload from "./EmployeeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: AssignmentIndIcon,
};
