import {
  AutocompleteInput,
  DateInput,
  FileInput,
  FileField,
  FormDataConsumer,
  required,
  useGetList,
} from 'react-admin';
import SodikSimpleForm from '@src/component/SodikSimpleForm';
import Stage from '@src/component/Stage';
import Grade from '@src/component/Grade';
import { Box, Grid } from '@mui/material';
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace,
} from '@src/themes/componentStyle';
import { longDisplayFormat } from '@src/helper/dateFormat';
import entity from '@src/entityResource';

const StudentYear = ({
  formData,
  getSource,
}: {
  formData: any;
  getSource: any;
}) => {
  const { data: students } = useGetList(entity.SCHOOL_YEAR_STUDENT.UNNOTED, {
    filter: {
      q: '',
      stageId: formData?.schoolYearStudent?.stageId,
      gradeId: formData?.schoolYearStudent?.gradeId,
      startDate: formData.startDate,
      endDate: formData.endDate,
    },
  });

  return (
    <AutocompleteInput
      source={getSource('schoolYearStudentId')}
      choices={students}
      optionText="student.name"
      optionValue="id"
      validate={required()}
    />
  );
};

const StudentReportForm = () => (
  <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <SodikSimpleForm>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <>
                  <Box flex={1} mr={boxItemMr}>
                    <DateInput
                      source="startDate"
                      validate={required()}
                      fullWidth
                      helperText={
                        formData.startDate
                          ? longDisplayFormat(formData.startDate, 'id-ID')
                          : ''
                      }
                    />
                  </Box>
                  <Box flex={1} mr={boxItemMr}>
                    <DateInput
                      source="endDate"
                      validate={required()}
                      fullWidth
                      helperText={
                        formData.endDate
                          ? longDisplayFormat(formData.endDate, 'id-ID')
                          : ''
                      }
                    />
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <Stage source="schoolYearStudent.stageId" isRequired={true} />
          </Box>
          <Box flex={1} mr={boxItemMr}>
            <FormDataConsumer>
              {({ formData }) => (
                <Grade
                  source="schoolYearStudent.gradeId"
                  formData={formData}
                  isRequired={true}
                />
              )}
            </FormDataConsumer>
          </Box>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <FormDataConsumer>
              {({ formData, getSource }) => (
                <StudentYear formData={formData} getSource={getSource} />
              )}
            </FormDataConsumer>
          </Box>
          <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>
            &nbsp;
          </Box>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <FileInput source="pdf" accept="application/pdf">
              <FileField source="src" title="title" />
            </FileInput>
          </Box>
          <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>
            &nbsp;
          </Box>
        </Box>
      </SodikSimpleForm>
    </Grid>
  </Grid>
);

export default StudentReportForm;
