import { Edit } from "react-admin";
import Form from "./SchoolCalenderForm";
import EditTitle from "@src/component/EditTitle";

const SchoolCalendarEdit = () => (
  <Edit title={<EditTitle />}>
    <Form />
  </Edit>
);

export default SchoolCalendarEdit;
