import React from "react";
import {
    AutocompleteInput,
    required,
    useGetList,
} from "react-admin";

const Emp = ({ formData, isRequired, path }: { formData: any, isRequired: boolean, path: string}) => {
    const { data: employees } = useGetList(path, {filter: { q:'', schoolYearId: formData.schoolYearId }});

    return (
      <React.Fragment>
        <AutocompleteInput
          source="employeeId"
          choices={employees}
          optionText="name"
          optionValue="id"
          validate={isRequired ? required() : undefined}
        />
      </React.Fragment>
    )
  }

  const Employee = ({formData, path, isRequired}: {formData: any, path: string, isRequired: boolean}) => <Emp formData={formData} isRequired={isRequired} path={path} />
  export default Employee;
