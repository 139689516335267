import { Create } from "react-admin";
import Form from "./SavingForm";

const SavingCreate = () => (
  <Create redirect="list" title="resources.savings.form.create">
    <Form />
  </Create>
);

export default SavingCreate;
