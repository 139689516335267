import { Create } from "react-admin";
import Form from "./RewardForm";

const RewardCreate = () => (
  <Create redirect="list" title="resources.rewards.form.create">
    <Form />
  </Create>
);

export default RewardCreate;
