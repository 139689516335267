import { Edit } from "react-admin";
import Form from "./RoleForm";
import EditTitle from "@src/component/EditTitle";

const RoleEdit = () => {
  return (
    <Edit title={<EditTitle />}>
      <Form />
    </Edit>
  );
};

export default RoleEdit;
