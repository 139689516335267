import { Datagrid, TextField, EditButton, SimpleList, useTranslate } from "react-admin";
import { useMediaQuery } from "@mui/material";
import { listRowSx } from "@src/themes/componentStyle";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";

const StageList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.stages.filters.search"
      uploadUrl={entity.STAGE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <>
              <div>{translate("resources.stages.fields.id")}&nbsp;<strong>{record.id}</strong></div>
              <div>{translate("resources.stages.fields.name")}&nbsp;<strong>{record.name}</strong></div>
            </>
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="whatsapp" />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default StageList;
