import React from 'react';
import {
  AutocompleteInput,
  useGetList,
  required,
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import entity from '@src/entityResource';

/**
 * @description Stage component. St stand for Stage
 * @returns 
 */
const St = ({ isRequired, source }: { isRequired: boolean; source: string }) => {
  const { data: stages } = useGetList(entity.STAGE.NAME);
  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (stages && stages.length === 1) {
      setValue(source, stages[0].id);
    }
  }, [stages]);

  return (
    <React.Fragment>
      <AutocompleteInput
        source={source}
        choices={stages}
        optionText="name"
        optionValue="id"
        validate={isRequired ? required() : undefined}
        fullWidth
      />
    </React.Fragment>
  );
}

const Stage = ({ isRequired, source }: { isRequired: boolean; source: string }) => <St source={source} isRequired={isRequired} />
export default Stage;
