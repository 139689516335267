import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";

const csvHeaders = [
  "kode_tahun_ajaran",
  "kode_sekolah",
  "kode_kelas",
  "nik",
];

interface ICsvData {
  kode_tahun_ajaran: string;
  kode_sekolah: string;
  kode_kelas: string;
  nik: string;
}

const SchoolYearEmployeeUpload = () => {
  const verifyData = async (csvData: ICsvData[]) => {
    const normalizedData = csvData.map((row: ICsvData) => {
      return {
        ...row,
        nik: row.nik.replace(/\s/g, "").replace(/"/g, ""),
      };
    });

    const schoolYearData = await request.getList({
      key: "kode_tahun_ajaran",
      csv: normalizedData,
      apiResource: entity.SCHOOL_YEAR.NAME,
      entityProp: "id",
    });

    const schoolData = await request.getList({
      key: "kode_sekolah",
      csv: normalizedData,
      apiResource: entity.STAGE.NAME,
      entityProp: "id",
    });

    const gradeData = await request.getList({
      key: "kode_kelas",
      csv: normalizedData,
      apiResource: entity.GRADE.NAME,
      entityProp: "code",
    });

    const employeeData = await request.getList({
      key: "nik",
      csv: normalizedData,
      apiResource: entity.EMPLOYEE.NAME,
      entityProp: "nik",
    });

    return normalizedData.map((row: ICsvData) => {
      const { kode_tahun_ajaran, kode_sekolah, kode_kelas, nik } = row;
      const schoolYear = schoolYearData
        ? schoolYearData.find((d: any) => +d.id === +kode_tahun_ajaran)
        : undefined;
      const stage = schoolData
        ? schoolData.find((d: any) => +d.id === +kode_sekolah)
        : undefined;
      const grade = gradeData
        ? gradeData.find((d: any) => d.code === kode_kelas)
        : undefined;
      const employee = employeeData
        ? employeeData.find((d: any) => +d.nik === +nik)
        : undefined;

      if (schoolYear && stage && grade && employee) {
        return {
          code: `${schoolYear.id}${employee.id}`,
          schoolYearId: schoolYear.id,
          stageId: stage.id,
          gradeId: grade.id,
          employeeId: employee.id,
        };
      }
    });
  };

  return (
    <React.Fragment>
      <Title title="schoolYearEmployeeUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="schoolYearEmployeeUpload.fileInputLabel"
        apiResource={entity.SCHOOL_YEAR_EMPLOYEE.API_UPLOAD}
        resource={entity.SCHOOL_YEAR_EMPLOYEE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default SchoolYearEmployeeUpload;
