import { useRecordContext, useTranslate } from "react-admin";

const EditTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const edit = translate("form.edit");
  return <span>{record ? `${record.name}` : edit}</span>;
};

export default EditTitle;
