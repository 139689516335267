import { Create } from "react-admin";
import Form from "./UserForm";

const UserCreate = () => (
  <Create redirect="list" title="resources.users.form.create">
    <Form />
  </Create>
);

export default UserCreate;
