const stringHelper = {
    /**
     * @description Parse value of string to number
     * @param v 
     * @returns 
    */
    removeSpace: (s: string):string => s.replace(/\s/g, "")
}

export const { removeSpace } = stringHelper;
