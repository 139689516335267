import ScheduleIcon from "@mui/icons-material/Schedule";
import list from "./SchoolScheduleList";
import create from "./SchoolScheduleCreate";
import edit from "./SchoolScheduleEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: ScheduleIcon,
};
