import { TextInput, BooleanInput, required, useGetList, useTranslate } from "react-admin";
import { Box, Grid, } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Generation from "@src/component/Generation";
import SchoolYear from "@src/component/SchoolYear";
import Stage from "@src/component/Stage";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxContainerFieldsetSx,
  boxItemMr,
  hideWhiteSpace,
} from "@src/themes/componentStyle";
import env from "@src/environment";

const InvoiceTypeForm = () => {
  const intl = env.localFormat;
  const translate = useTranslate();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Box flex={1} display={boxContainerDisplay} component="fieldset" sx={boxContainerFieldsetSx}>
                <legend>{translate("resources.invoice-types.fields.category")}</legend>
                <BooleanInput source="isMonthly" />
                <BooleanInput source="isYearly" />
              </Box>
            </Box>
            <Box flex={1}>&nbsp;</Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <SchoolYear />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <Generation source="generationId" isRequired={true} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <Stage source="stageId" isRequired={true} />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="name"
                validate={required()}
                resettable={true}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="amount"
                defaultValue={0}
                validate={required()}
                format={(v) => Intl.NumberFormat(intl).format(v)}
                parse={(v) =>
                  isNaN(parseInt(v.replace(/[^0-9]/g, "")))
                    ? 0
                    : parseInt(v.replace(/[^0-9]/g, ""))
                }
                resettable={true}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={boxItemMr} display={hideWhiteSpace}>
              &nbsp;
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default InvoiceTypeForm;
