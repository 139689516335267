import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, Resource, useStore } from 'react-admin';
import { Route } from 'react-router-dom';
import { Layout, Login, ResetPassword } from './layout';
import { authProvider } from './authProvider';
import { dataProvider } from './dataProvider';
import { themes, ThemeName } from './themes/themes';
import { Dashboard } from './dashboard/dashboard';
import indonesiaMessages from './i18n/id';
import stage from './stage';
import grade from './grade';
import generation from './generation';
import schoolYear from './school-year';
import schoolGuideline from './school-guideline';
import employee from './employee';
import student from './student';
import invoiceType from './invoice-type';
import schoolYearStudent from './school-year-student';
import schoolYearInvoice from './school-year-invoice';
import schoolYearEmployee from './school-year-employee';
import highlightType from './highlight-type';
import schoolHighlight from './school-highlight';
import schoolSchedule from './school-schedule';
import schoolCalendar from './school-calendar';
import noteType from './note-type';
import schoolYearStudentNote from './school-year-student-note';
import studentReport from './student-report';
import user from './user';
import role from './role';
import rewardType from './reward-type';
import reward from './reward';
import saving from './saving';
import entity from './entityResource';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'en') {
      return import('./i18n/en').then((messages) => messages.default);
    }
    // Always fallback on indonesia
    return indonesiaMessages;
  },
  'id',
  [
    { locale: 'en', name: 'English' },
    { locale: 'id', name: 'Indonesia' },
  ],
);

export const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;

  return (
    <Admin
      title="MIS"
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme="light"
    >
      <Resource
        name={entity.STAGE.NAME}
        {...stage}
        recordRepresentation="name"
      />
      <Resource
        name={entity.GRADE.NAME}
        {...grade}
        recordRepresentation="name"
      />
      <Resource
        name={entity.EMPLOYEE.NAME}
        {...employee}
        recordRepresentation="name"
      />
      <Resource
        name={entity.STUDENT.NAME}
        {...student}
        recordRepresentation="name"
      />
      <Resource
        name={entity.GENERATION.NAME}
        {...generation}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_YEAR.NAME}
        {...schoolYear}
        recordRepresentation="name"
      />
      <Resource
        name={entity.INVOICE_TYPE.NAME}
        {...invoiceType}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_YEAR_STUDENT.NAME}
        {...schoolYearStudent}
        recordRepresentation="studentName"
      />
      <Resource name={entity.SCHOOL_YEAR_INVOICE.NAME} {...schoolYearInvoice} />
      <Resource
        name={entity.SCHOOL_YEAR_EMPLOYEE.NAME}
        {...schoolYearEmployee}
        recordRepresentation="employeeName"
      />
      <Resource
        name={entity.HIGHLIGHT_TYPE.NAME}
        {...highlightType}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_HIGHLIGHT.NAME}
        {...schoolHighlight}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_SCHEDULE.NAME}
        {...schoolSchedule}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_CALENDAR.NAME}
        {...schoolCalendar}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_GUIDELINE.NAME}
        {...schoolGuideline}
        recordRepresentation="name"
      />
      <Resource
        name={entity.NOTE_TYPE.NAME}
        {...noteType}
        recordRepresentation="name"
      />
      <Resource
        name={entity.SCHOOL_YEAR_STUDENT_NOTE.NAME}
        {...schoolYearStudentNote}
      />
      <Resource name={entity.STUDENT_REPORT.NAME} {...studentReport} />
      <Resource name={entity.USER.NAME} {...user} recordRepresentation="name" />
      <Resource name={entity.ROLE.NAME} {...role} recordRepresentation="name" />
      <Resource
        name={entity.REWARD_TYPE.NAME}
        {...rewardType}
        recordRepresentation="name"
      />
      <Resource
        name={entity.REWARD.NAME}
        {...reward}
        recordRepresentation="name"
      />
      <Resource name={entity.SAVING.NAME} {...saving} />
      <CustomRoutes>
        <Route path={entity.STAGE.UPLOAD_PATH} element={<stage.upload />} />
        <Route path={entity.GRADE.UPLOAD_PATH} element={<grade.upload />} />
        <Route
          path={entity.SCHOOL_YEAR.UPLOAD_PATH}
          element={<schoolYear.upload />}
        />
        <Route path={entity.STUDENT.UPLOAD_PATH} element={<student.upload />} />
        <Route
          path={entity.EMPLOYEE.UPLOAD_PATH}
          element={<employee.upload />}
        />
        <Route
          path={entity.INVOICE_TYPE.UPLOAD_PATH}
          element={<invoiceType.upload />}
        />
        <Route
          path={entity.SCHOOL_YEAR_STUDENT.UPLOAD_PATH}
          element={<schoolYearStudent.upload />}
        />
        <Route
          path={entity.SCHOOL_YEAR_EMPLOYEE.UPLOAD_PATH}
          element={<schoolYearEmployee.upload />}
        />
        <Route
          path={entity.SCHOOL_YEAR_INVOICE.UPLOAD_PATH}
          element={<schoolYearInvoice.upload />}
        />
        <Route
          path={entity.SCHOOL_YEAR_INVOICE.GENERATE_PATH}
          element={<schoolYearInvoice.generate />}
        />
        <Route
          path={entity.HIGHLIGHT_TYPE.UPLOAD_PATH}
          element={<highlightType.upload />}
        />
        <Route
          path={entity.SCHOOL_CALENDAR.UPLOAD_PATH}
          element={<schoolCalendar.upload />}
        />
        <Route
          path={entity.NOTE_TYPE.UPLOAD_PATH}
          element={<noteType.upload />}
        />
        <Route path={entity.ROLE.UPLOAD_PATH} element={<role.upload />} />
        <Route
          path={entity.REWARD_TYPE.UPLOAD_PATH}
          element={<rewardType.upload />}
        />
        <Route path={entity.SAVING.UPLOAD_PATH} element={<saving.upload />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path={entity.USER.RESET_PWD} element={<ResetPassword />} />
      </CustomRoutes>
    </Admin>
  );
};
