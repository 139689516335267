import EventNoteIcon from "@mui/icons-material/EventNote";
import list from "./SchoolCalendarList";
import create from "./SchoolCalenderCreate";
import edit from "./SchoolCalenderEdit";
import upload from "./SchoolCalendarUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: EventNoteIcon,
};
