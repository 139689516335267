import Papa from 'papaparse';
import { Datagrid, SimpleList, TextField, EditButton, FunctionField, useTranslate, useGetList, downloadCSV } from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";
import { getStageAndGrade } from "@src/helper/record";
import { displayFormatWithTime } from "@src/helper/dateFormat";

const StudentList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { data } = useGetList(entity.STUDENT.EXPORT, { filter: {}, pagination: { page: 1, perPage: 1000 } });

  const onExport = async () => {
    if (data) {
      const studentData = data.map((item: any) => {
        return {
          ...item,
          nik: item.nik ? `"${item.nik}"` : '',
          nis: item.nis ? `"${item.nis}"` : '',
          familyCardNo: item.familyCardNo ? `"${item.familyCardNo}"` : '',
          fatherIdNo: item.fatherIdNo ? `"${item.fatherIdNo}"` : '',
          fatherPhoneNo: item.fatherPhoneNo ? `"${item.fatherPhoneNo}"` : '',
          motherIdNo: item.motherIdNo ? `"${item.motherIdNo}"` :'',
          motherPhoneNo: item.motherPhoneNo ? `"${item.motherPhoneNo}"` : '',
          communicationPhoneNo: item.communicationPhoneNo ? `"${item.communicationPhoneNo}"` : '',
        }
      });
      const csv = Papa.unparse(studentData);
      const fname = `DataSiswa-${displayFormatWithTime(new Date().toISOString(), 'id-ID')}`;
      const filename = fname.replace(/ /g, '').replace(',', '-').replace('.', '');
      downloadCSV(csv, filename);
    }
  }

  return (
    <SodikInfiniteList
      filterLabel="resources.students.filters.search"
      uploadUrl={entity.STUDENT.UPLOAD_URL}
      onExport={onExport}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <>
              <div>{translate("resources.students.fields.generation.name")}&nbsp;{record?.generation?.code}</div>
              <div>{getStageAndGrade(record?.schoolYearStudent[0])}</div>
              {record.nis && <div>{translate("resources.students.fields.nis")}&nbsp;{record.nis}</div>}
              <div>{translate("resources.students.fields.name")}&nbsp;<strong>{record.name}</strong></div>
            </>
          )}
          secondaryText={(record) => record.nis}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="generation.name" />
          <FunctionField
            label="resources.users.fields.currentStudent"
            render={(record: any) => getStageAndGrade(record?.schoolYearStudent[0])}
          />
          <TextField source="nik" />
          <TextField source="nis" />
          <TextField source="name" />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default StudentList;
