import * as React from "react";
import { ListProps, SimpleForm } from "react-admin";
import FormToolbar from "./FormToolbar";

interface ISodikSimpleForm extends ListProps {
  isDelete?: boolean;
}

const SodikSimpleForm: React.FC<ISodikSimpleForm> = ({
  children,
  isDelete = true,
  //...rest
}) => {
  return (
    <SimpleForm sanitizeEmptyValues toolbar={<FormToolbar isDelete={isDelete} />}>
      {children}
    </SimpleForm>
  );
};

export default SodikSimpleForm;
