import {
  DateInput,
  FormDataConsumer,
  SimpleForm,
  Title,
  useCreate,
  useRedirect,
  useNotify
} from "react-admin";
import { Box } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import ent from "@src/entityResource";
import { longDisplayFormat } from "@src/helper/dateFormat";

const SchoolYearInvoiceGenerate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSave = async (data: any) => {
    create(
      ent.SCHOOL_YEAR_INVOICE.API_GENERATE,
      { data: { invoiceDate: data.invoiceDate } },
      {
        onSuccess: () => {
          redirect("list", ent.SCHOOL_YEAR_INVOICE.NAME);
          notify("csvUpload.notifications.success", { type: "info" });
        },
      }
    );
  }

  return (
    <>
      <Title title="resources.school-year-invoices.form.generate" />
      <SimpleForm onSubmit={onSave}>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <DateInput source="invoiceDate" label="resources.school-year-invoices.form.periode" fullWidth helperText={formData.invoiceDate ? longDisplayFormat(formData.invoiceDate, "id-ID") : ""} />
                );
              }}
            </FormDataConsumer>

          </Box>
          <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
        </Box>
      </SimpleForm>
    </>
  );
}

export default SchoolYearInvoiceGenerate;
