import React from "react";
import {
  FormDataConsumer,
  TextInput,
  useGetRecordId
} from "react-admin";
import { Box, Grid } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import SchoolYear from "@src/component/SchoolYear";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import Employee from "@src/component/Employee";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr
} from "@src/themes/componentStyle";
import entity from "@src/entityResource";

/**
 * @description Check record id to differentiate between create and edit
 * @returns 
 */
const checkRecordId = () => {
  try {
    const recordId = useGetRecordId();
    return Number(recordId);
  } catch (error) {
    return null;
  }
}

const SchoolYearEmployeeForm = () => {
  const recordId = checkRecordId();
  
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <SchoolYear />
            </Box>
            <Box
              flex={1}
              mr={boxItemMr}
            >
              <Stage source="stageId" isRequired={true} />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <FormDataConsumer>
                {({ formData }) => {
                  return(
                    <React.Fragment>
                      <Box flex={1} mr={boxItemMr}>
                        <Grade source="gradeId" formData={formData} isRequired={true} />
                      </Box>
                      <Box flex={1} mr={boxItemMr}>
                        {
                          recordId ? (
                            <TextInput
                              source="employee.name"
                              fullWidth
                            />
                          ) : (
                            <FormDataConsumer>
                              {({ formData }) => <Employee formData={formData} isRequired={true} path={entity.EMPLOYEE.UNREGISTER} /> }
                            </FormDataConsumer>
                          )
                        }
                      </Box>
                    </React.Fragment>
                  )
                } }
              </FormDataConsumer>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolYearEmployeeForm;
