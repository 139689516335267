import React from 'react';
import {
  Datagrid,
  EditButton,
  FunctionField,
  SimpleList,
  TextField,
  useTranslate,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { iFile } from '@src/component/iFile';
import PreviewFile from '@src/component/PreviewFile';
import FileViewer from '@src/component/FileViewer';
import SodikInfiniteList from '@src/component/SodikInfiniteList';
import { listRowSxWithButton } from '@src/themes/componentStyle';
import { getStageAndGrade } from '@src/helper/record';

const getGrade = (record: any) => {
  if (record?.schoolYearStudent) {
    return getStageAndGrade(record?.schoolYearStudent);
  }

  return '';
};

const StudentReportList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<iFile>();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const handleOpen = (r: any) => {
    setFile({
      title: r?.schoolYearStudent?.student?.name,
      fileUrl: r.fileUrl,
      fileName: r.fileName,
    });
    setOpen(true);
  };

  return (
    <SodikInfiniteList
      filterLabel="resources.student-reports.filters.search"
      uploadUrl={''}
    >
      {isSmall ? (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <SimpleList
            primaryText={(record) => (
              <>
                <div style={{ display: 'flex' }}>
                  <div>
                    <strong>{record?.schoolYearStudent?.student?.name}</strong>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>{getGrade(record)}</div>
                </div>
              </>
            )}
            secondaryText={() => (
              <div style={{ display: 'flex', marginTop: 8 }}>
                <div
                  style={{
                    flex: 1,
                    border: 1,
                    marginRight: 1,
                    borderRadius: 8,
                    borderStyle: 'solid',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <PreviewFile
                      onOpen={handleOpen}
                      label={translate('amip.common.view')}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    border: 1,
                    marginLeft: 1,
                    borderRadius: 8,
                    borderStyle: 'solid',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <EditButton />
                  </div>
                </div>
              </div>
            )}
            linkType={false}
            rowSx={listRowSxWithButton}
          />
        </>
      ) : (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <Datagrid>
            <FunctionField
              label="resources.student-reports.fields.gradeId"
              render={(record: any) => getGrade(record)}
            />
            <TextField source="schoolYearStudent.student.name" />
            <>
              <PreviewFile
                onOpen={handleOpen}
                label={translate('amip.common.view')}
              />
              <EditButton />
            </>
          </Datagrid>
        </>
      )}
    </SodikInfiniteList>
  );
};

export default StudentReportList;
