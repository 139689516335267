const recordHelper = {
    getStageAndGrade: (entity: any) => {
        if (entity) {
            const grade = getGrade(entity?.grade);
            return `${entity?.stage?.name} - ${grade}`;
        }

        return '';
    },

    getGrade: (grade: any) => {
        if (grade) {
            return `${grade?.alphanumeric}${grade?.sub} ${grade?.name}`;
        }
        return '';
    },
}

export const { getStageAndGrade, getGrade } = recordHelper;