import { Edit, useGetRecordId, useGetOne } from "react-admin";
import Form from "./SchoolYearStudentForm";
import entity from "@src/entityResource";

const SchoolYearStudentEdit = () => { 
  const recordId = useGetRecordId();
  const {data: schoolYearStudent} = useGetOne(entity.SCHOOL_YEAR_STUDENT.NAME, {id: recordId ?? 0});
  const title = schoolYearStudent?.student && schoolYearStudent?.grade ? `${schoolYearStudent?.student.name} - ${schoolYearStudent?.grade.name} ${schoolYearStudent?.grade.alphanumeric}.${schoolYearStudent?.grade.sub}` : 'resources.school-year-students.name';
  return(
    <Edit title={title}>
      <Form />
    </Edit>
  )
};

export default SchoolYearStudentEdit;
