import RecentActorsIcon from '@mui/icons-material/RecentActors';
import list from "./UserList";
import create from "./UserCreate";
import edit from "./UserEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: RecentActorsIcon,
};
