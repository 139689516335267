import { TextInput, required } from "react-admin";
import { Box, Grid, } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
} from "@src/themes/componentStyle";

const RewardTypeForm = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="name"
                validate={required()}
                resettable={true}
                fullWidth
              />
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default RewardTypeForm;
