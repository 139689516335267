import { Create } from "react-admin";
import Form from "./InvoiceTypeForm";

const InvoiceTypeCreate = () => {
  return (
    <Create redirect="list" title="resources.invoice-types.form.create">
      <Form />
    </Create>
  );
};

export default InvoiceTypeCreate;
