import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import list from "./SchoolHighlightList";
import create from "./SchoolHighlightCreate";
import edit from "./SchoolHighlightEdit";

export default {
  list: list,
  create: create,
  edit: edit,
  icon: BedroomBabyIcon,
};
