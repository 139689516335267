import { AuthProvider } from "react-admin";
import env from "./environment";

export const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const request = new Request(`${env.apiUrl}/auth`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    try {
      const result = await fetch(request);
      if (result.status < 200 || result.status >= 300) {
        throw new Error(result.statusText);
      }

      const auth = await result.json();
      localStorage.setItem("auth", JSON.stringify(auth));
      localStorage.setItem("permissions", auth.role);
    } catch (error) {
      throw new Error("Network error");
    }
  },

  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },

  checkError: (response) => {
    if (response) {
      const { status } = response;
      if (status === 401 || status === 403) {
        localStorage.removeItem("auth");
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  }
};
