import { Create } from "react-admin";
import Form from "./SchoolYearForm";

const SchoolYearCreate = () => (
  <Create redirect="list" title="resources.school-years.form.create">
    <Form />
  </Create>
);

export default SchoolYearCreate;
