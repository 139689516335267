import {
  AutocompleteInput,
  required,
  ReferenceInput
} from "react-admin";

const St = ({ isRequired, path }: { isRequired: boolean, path: string }) =>
  <ReferenceInput
    source="studentId"
    reference={path}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      validate={isRequired ? required() : undefined}
      debounce={300}
    />
  </ReferenceInput>

const Student = ({ isRequired, path }: { isRequired: boolean, path: string }) => <St isRequired={isRequired} path={path} />
export default Student;
