import { Edit } from "react-admin";
import Form from "./SchoolYearForm";
import EditTitle from "@src/component/EditTitle";

const SchoolYearEdit = () => (
  <Edit title={<EditTitle />}>
    <Form />
  </Edit>
);

export default SchoolYearEdit;
