import {
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  SimpleList,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { listRowSx } from "@src/themes/componentStyle";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { getGrade } from "@src/helper/record";

const GradeList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.grades.filters.search"
      uploadUrl={entity.GRADE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <>
              <div>{translate("resources.grades.fields.code")}&nbsp;<strong>{record.code}</strong></div>
              <div>{translate("resources.grades.fields.name")}&nbsp;{getGrade(record)}</div>
            </>
          )}
          secondaryText={(record: any) => record.stage.name}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="stage.name" />
          <TextField source="code" />
          <FunctionField
            label="resources.grades.fields.name"
            render={(record: any) => `${record.alphanumeric}${record.sub} ${record.name}`}
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};
export default GradeList;
