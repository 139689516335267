import React from "react";
import {
  FormDataConsumer,
  FunctionField,
  TextInput,
  BooleanInput,
  Labeled,
  useGetRecordId,
  useTranslate,
  required,
} from "react-admin";
import { Box, Grid, TextField as MUITextField, } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Student from "@src/component/Student";
import Employee from "@src/component/Employee";
import Role from "@src/component/Role";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import entity from "@src/entityResource";

const getGrade = (record: any) => {
  if (record?.student?.schoolYearStudent[0]) {
    if (record?.student?.schoolYearStudent[0]?.grade) {
      return `${record?.student?.schoolYearStudent[0]?.stage?.name} - ${record?.student?.schoolYearStudent[0]?.grade?.name} ${record?.student?.schoolYearStudent[0].grade?.alphanumeric}.${record?.student?.schoolYearStudent[0]?.grade?.sub}`;
    }
    return `${record?.student?.schoolYearStudent[0]?.stage?.name}`;
  }

  if (record?.employee?.schoolYearEmployee[0]) {
    if (record?.employee?.schoolYearEmployee[0]?.grade) {
      return `${record?.employee?.schoolYearEmployee[0]?.stage?.name} - ${record?.employee?.schoolYearEmployee[0]?.grade?.name} ${record?.employee?.schoolYearEmployee[0].grade?.alphanumeric}.${record?.employee?.schoolYearEmployee[0]?.grade?.sub}`;
    }
    return `${record?.employee?.schoolYearEmployee[0]?.stage?.name}`;
  }

  return "";
}

const checkRecordId = () => {
  try {
    const recordId = useGetRecordId();
    return Number(recordId);
  } catch (error) {
    return null;
  }
}

const UserForm = () => {
  const recordId = checkRecordId();
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <FormDataConsumer>
            {({ formData }) => {
              const isFilled = Boolean(formData.name || formData.studentId || formData.employeeId);
              return (
                <React.Fragment>
                  {
                    !recordId &&
                    <>
                      <Box display={boxContainerDisplay} sx={boxContainerSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <Labeled label="resources.users.hint.name" fullWidth>
                            <TextInput
                              source="name"
                              resettable={true}
                              fullWidth
                              required={!isFilled}
                            />
                          </Labeled>
                        </Box>
                        <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <Labeled label="resources.users.hint.student" fullWidth>
                            <Student isRequired={!isFilled} path={entity.STUDENT.UNLISTED} />
                          </Labeled>
                        </Box>
                        <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <Labeled label="resources.users.hint.employee" fullWidth>
                            <Employee formData={formData} isRequired={!isFilled} path={entity.EMPLOYEE.UNLISTED} />
                          </Labeled>
                        </Box>
                        <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
                      </Box>
                    </>
                  }
                </React.Fragment>
              )
            }}
          </FormDataConsumer>
          {
            recordId &&
            <Box mb={2} display={boxContainerDisplay} sx={boxContainerSx}>
              <Box flex={1} mr={boxItemMr}>
                <FunctionField
                  render={(record: any) =>
                    <MUITextField
                      label={translate("resources.users.fields.name")}
                      id="name"
                      value={record?.student?.name ? record?.student?.name : record.employee?.name}
                      fullWidth
                    />
                  }
                />
              </Box>
              <Box flex={1}>
                <FunctionField
                  render={(record: any) =>
                    record?.student?.schoolYearStudent[0]
                      ? <Box flex={1} mr={boxItemMr}>
                        <MUITextField
                          label={translate("resources.users.fields.grade")}
                          id="grade"
                          value={getGrade(record)}
                          fullWidth
                        />
                      </Box>
                      : <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
                  }
                />
              </Box>
            </Box>
          }
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Role isRequired={true} />
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="username"
                validate={required()}
                resettable={true}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="password"
                type="password"
                validate={recordId ? undefined : required()}
                resettable={true}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <BooleanInput source="isActive" validate={required()} />
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default UserForm;
