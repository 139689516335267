import {
    Datagrid,
    SimpleList,
    TextField,
    EditButton,
    useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { listRowSx } from "@src/themes/componentStyle";
import entity from "@src/entityResource";

const GenerationList = () => {
    const translate = useTranslate();
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

    return (
        <SodikInfiniteList
            filterLabel="resources.generations.filters.search"
            uploadUrl={entity.GENERATION.UPLOAD_URL}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => (
                        <div>{translate("resources.generations.fields.code")}&nbsp;<strong>{record.code}</strong></div>
                    )}
                    secondaryText={(record) => (
                        <div>{translate("resources.generations.fields.name")}&nbsp;<strong>{record.name}</strong></div>
                    )}
                    linkType={(record) => (record.canEdit ? "edit" : "show")}
                    rowSx={listRowSx}
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="name" />
                    <EditButton />
                </Datagrid>
            )}
        </SodikInfiniteList>
    );
};

export default GenerationList;
