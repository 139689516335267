import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";
import { removeSpace } from "@src/helper/string";

const headers = ["nama"];

interface ICsvData {
  nama: string;
}

const verifyData = async (csvData: ICsvData[]) => {
  return csvData.map((row: ICsvData) => {
    const { nama } = row;

    return {
      name: nama
    };
  });
};

const RewardTypeUpload = () => {
  return (
    <React.Fragment>
      <Title title="rewardTypeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="rewardTypeUpload.fileInputLabel"
        apiResource={entity.REWARD_TYPE.API_UPLOAD}
        resource={entity.REWARD_TYPE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default RewardTypeUpload;
