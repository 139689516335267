import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
  useLocaleState,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import entity from "@src/entityResource";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { displayFormat } from "@src/helper/dateFormat";
import { listRowSx } from "@src/themes/componentStyle";

const SchoolCalendarList = () => {
  const [locale] = useLocaleState();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-calendars.filters.search"
      uploadUrl={entity.SCHOOL_CALENDAR.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.name}`}
          secondaryText={(record: any) => (
            <>
              <div style={{ display: 'flex' }}>
                <div>{record?.stage?.name} {record?.schoolYear?.name}</div>
              </div>
              <div style={{ display: 'flex' }}>
                <div><strong>{displayFormat(record.startDate, locale)} - {displayFormat(record.endDate, locale)}</strong></div>
              </div>
            </>
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYear.name" />
          <TextField source="stage.name" />
          <TextField source="grade.name" />
          <TextField source="name" />
          <FunctionField
            label="Periode"
            render={(record: any) =>
              `${displayFormat(record.startDate, locale)} - ${displayFormat(
                record.endDate,
                locale
              )}`
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolCalendarList;
