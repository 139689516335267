import React from "react";
import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { iFile } from "@src/component/iFile";
import PreviewFile from "@src/component/PreviewFile";
import FileViewer from "@src/component/FileViewer";
import entity from "@src/entityResource";
import { displayFormat, longDisplayFormat } from "@src/helper/dateFormat";
import { listRowSxWithButton } from "@src/themes/componentStyle";
import { getGrade } from "@src/helper/record";

const SchoolScheduleList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<iFile>();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const handleOpen = (r: any) => {
    setFile({
      title: r.stage.name + " - " + r.grade.name,
      fileUrl: r.fileUrl,
      fileName: r.fileName
    });
    setOpen(true);
  };

  return (
    <SodikInfiniteList
      filterLabel="resources.school-schedules.filters.search"
      uploadUrl={entity.SCHOOL_SCHEDULE.UPLOAD_URL}
    >
      {isSmall ? (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <SimpleList
            // primaryText={(record) => `${record.title} - ${record.stage.name}`}
            primaryText={(record) => (
              <>
                <div style={{ display: 'flex' }}>
                  Periode&nbsp;<strong>{displayFormat(record.startDate, locale)} - {displayFormat(record.endDate, locale)}</strong>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>{record?.stage?.name}<strong>&nbsp;{getGrade(record?.grade)}</strong></div>
                </div>
              </>
            )}
            secondaryText={(record) => (
              <>
                <hr />
                <div style={{ display: 'flex', marginTop: 8 }}>
                  <div style={{ flex: 1, border: 1, marginRight: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
                    </div>
                  </div>
                  <div style={{ flex: 1, border: 1, marginLeft: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <EditButton />
                    </div>
                  </div>
                </div>
              </>
            )}
            linkType={false}
            rowSx={listRowSxWithButton}
          />
        </>
      ) : (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <Datagrid>
            <TextField source="stage.name" />
            <FunctionField
              label="resources.school-year-students.fields.grade.name"
              render={(record: any) => `${record.grade.alphanumeric}${record.grade.sub} ${record.grade.name}`
              }
            />
            <FunctionField
              label="Periode"
              render={(record: any) => record.scheduleDate ? longDisplayFormat(record.scheduleDate, locale) : `${longDisplayFormat(record.startDate, locale)} - ${longDisplayFormat(record.endDate, locale)}`}
            />
            <>
              <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
              <EditButton />
            </>
          </Datagrid>
        </>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolScheduleList;
