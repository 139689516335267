import React from 'react';
import {
    AutocompleteInput,
    useGetList,
    required,
  } from "react-admin";
import entity from '@src/entityResource';

/**
 * @description Role component. Rl stand for Role
 * @returns 
 */
const Rl = ({isRequired}:{isRequired:boolean}) => {
    const { data: stages } = useGetList(entity.ROLE.NAME);

    return (
      <React.Fragment>
        <AutocompleteInput
          source="roleId"
          choices={stages}
          optionText="name"
          optionValue="id"
          validate={isRequired ? required() : undefined}
          fullWidth
        />
      </React.Fragment>
    );
}

const Role = ({isRequired}:{isRequired:boolean}) => <Rl isRequired={isRequired} />
export default Role;
