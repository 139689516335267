import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";

interface ICsvData {
    kode_angkatan: string;
    nama_angkatan: string;
}

const csvHeaders = ["kode_angkatan", "nama_angkatan"];

const GenerationUpload = () => {
    const verifyData = (data: ICsvData[]) => {
        return data.map((d: ICsvData) => {
            const { kode_angkatan, nama_angkatan } = d;
            return {
                ...(nama_angkatan && { name: nama_angkatan }),
                ...(kode_angkatan && { code: kode_angkatan }),
            };
        });
    };

    return (
        <React.Fragment>
            <Title title="generationUpload.title" />
            <CsvUpload
                headers={csvHeaders}
                fileInputLabel="generationUpload.fileInputLabel"
                apiResource={entity.GENERATION.API_UPLOAD}
                resource={entity.GENERATION.NAME}
                verifyData={verifyData}
            />
        </React.Fragment>
    );
};

export default GenerationUpload;
