import { TabbedForm, TextInput, required } from "react-admin";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
} from "@src/themes/componentStyle";
import Generation from "@src/component/Generation";
import FormToolbar from "@src/component/FormToolbar";
import { toInt, format } from "@src/helper/number";

const StudentForm = () => (
  <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <TabbedForm sanitizeEmptyValues toolbar={<FormToolbar />}>
        <TabbedForm.Tab label="resources.students.tabs.student">
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Generation source="generationId" isRequired={true} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="familyCardNo"
                validate={required()}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="nik" validate={required()} fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="nis" fullWidth />
            </Box>
            <Box flex={2} mr={boxItemMr}>
              <TextInput source="name" validate={required()} fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="previousSchool" fullWidth />
            </Box>
            <Box flex={2} mr={boxItemMr}>
              <TextInput source="nickname" fullWidth />
            </Box>
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.students.tabs.communication">
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="communicationPhoneNo"
                validate={required()}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="communicationEmail"
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <Box flex={1} mr={boxItemMr}>
                <TextInput source="longlat" fullWidth />
              </Box>
            </Box>
            <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <Box
                flex={1}
                display={{ xs: "none", sm: "none", md: "block" }}
                mr={boxItemMr}
              >
                &nbsp;
              </Box>
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <Box flex={1} mr={boxItemMr}>
                <TextInput source="address" multiline fullWidth />
              </Box>
            </Box>
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.students.tabs.father">
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="fatherIdNo" fullWidth />
            </Box>
            <Box flex={2} mr={boxItemMr}>
              <TextInput source="fatherName" fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="fatherPhoneNo" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="fatherEmail" fullWidth />
            </Box>
            <Box flex={1} display={{ xs: "none", sm: "block" }} mr={boxItemMr}>
              &nbsp;
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="fatherOccupation" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="fatherSalary" format={format} parse={toInt} defaultValue={0} fullWidth />
            </Box>
            <Box flex={1} display={{ xs: "none", sm: "block" }} mr={boxItemMr}>
              &nbsp;
            </Box>
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.students.tabs.mother">
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="motherIdNo" fullWidth />
            </Box>
            <Box flex={2} mr={boxItemMr}>
              <TextInput source="motherName" fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="motherPhoneNo" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="motherEmail" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="motherOccupation" fullWidth />
            </Box>
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Grid>
  </Grid>
);

export default StudentForm;
