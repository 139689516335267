import { Edit, useGetRecordId, useGetOne } from "react-admin";
import Form from "./SchoolYearStudentNoteForm";
import entity from "@src/entityResource";

const SchoolYearStudentNoteEdit = () => {
  const recordId = useGetRecordId();
  const {data: note} = useGetOne(entity.SCHOOL_YEAR_STUDENT_NOTE.NAME, {id: recordId ?? 0});
  const title = note?.schoolYearStudent?.student?.name;

  return (
    <Edit title={title}>
      <Form />
    </Edit>
  )
};

export default SchoolYearStudentNoteEdit;
