import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";

const headers = ["kode_sekolah", "kategori"];

interface ICsvData {
  kode_sekolah: string;
  kategori: string;
}

const verifyData = async (csvData: ICsvData[]) => {
  const stageData = await request.getList({
    key: "kode_sekolah",
    csv: csvData,
    apiResource: entity.STAGE.NAME,
    entityProp: "id",
  });

  return csvData.map((row: ICsvData) => {
    const { kode_sekolah, kategori } = row;
    const stage = stageData ? stageData.find((d: any) => +d.id === +kode_sekolah) : undefined;

    return {
      ...(stage && { stageId: stage.id }),
      ...(stage && { name: kategori }),
    };
  });
};

const NoteTypeUpload = () => {
  return (
    <React.Fragment>
      <Title title="noteTypeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="noteTypeUpload.fileInputLabel"
        apiResource={entity.NOTE_TYPE.API_UPLOAD}
        resource={entity.NOTE_TYPE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default NoteTypeUpload;
