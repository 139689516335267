import { Create } from "react-admin";
import Form from "./GenerationForm";

const GenerationCreate = () => (
    <Create redirect="list" title="resources.generations.form.create">
        <Form />
    </Create>
);

export default GenerationCreate;
