import { Edit } from "react-admin";
import Form from "./RewardTypeForm";
import EditTitle from "@src/component/EditTitle";

const RewardTypeEdit = () => {
  return (
    <Edit title={<EditTitle />}>
      <Form />
    </Edit>
  );
};

export default RewardTypeEdit;
