import React from "react";
const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
      default: module.RichTextInput,
  }))
);
import { FileInput, FileField, TextInput, required } from "react-admin";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import SchoolYear from "@src/component/SchoolYear";
import Stage from "@src/component/Stage";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";

const SchoolGuidelineForm = () => (
  <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <SodikSimpleForm>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <SchoolYear />
          </Box>
          <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <Stage source="stageId" isRequired={true} />
          </Box>
          <Box flex={1} mr={boxItemMr}>
            <TextInput
              source="name"
              validate={required()}
              resettable={true}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
          <Box flex={1} mr={boxItemMr}>
            <RichTextInput source='description' />
          </Box>
          <Box flex={1} mr={boxItemMr}>
            <FileInput source="pdf" accept="application/pdf">
                <FileField source="src" title="title" />
            </FileInput>
          </Box>
        </Box>
      </SodikSimpleForm>
    </Grid>
  </Grid>
);

export default SchoolGuidelineForm;
