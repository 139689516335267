import { Create } from "react-admin";
import Form from "./NoteTypeForm";

const NoteTypeCreate = () => {
  return (
    <Create redirect="list" title="resources.invoice-types.form.create">
      <Form />
    </Create>
  );
};

export default NoteTypeCreate;
