import { Toolbar, SaveButton, ListButton, DeleteButton } from "react-admin";

const FormToolbar = ({isDelete}: {isDelete?:boolean}) => {
  return (
    <Toolbar>
      <SaveButton variant="text" size="medium" />
      <ListButton color="secondary" size="medium" />
      {isDelete && <DeleteButton size="medium" />}
    </Toolbar>
  );
};

export default FormToolbar;
