import React from 'react';
import {
    AutocompleteInput,
    useGetList,
    required,
} from "react-admin";
import entity from '@src/entityResource';

/**
 * @description Generation component. Gn stand for Generation
 * @returns 
 */
const Gn = ({ isRequired, source }: { isRequired: boolean; source: string }) => {
    const { data: stages } = useGetList(entity.GENERATION.NAME);

    return (
        <React.Fragment>
            <AutocompleteInput
                source={source}
                choices={stages}
                optionText="name"
                optionValue="id"
                validate={isRequired ? required() : undefined}
                fullWidth
            />
        </React.Fragment>
    );
}

const Generation = ({ isRequired, source }: { isRequired: boolean; source: string }) => <Gn source={source} isRequired={isRequired} />
export default Generation;
