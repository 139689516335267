import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";

interface ICsvData {
  nama_sekolah: string;
  tlp: string;
  email: string;
  whatsapp: string;
}

const csvHeaders = ["nama_sekolah", "tlp", "email", "whatsapp"];

const StageUpload = () => {
  const verifyData = (data: ICsvData[]) => {
    const normalizedData = data.map((row: ICsvData) => {
      return {
        ...row,
        tlp: row.tlp.replace(/\s/g, "").replace(/"/g, ""),
        whatsapp: row.whatsapp.replace(/\s/g, "").replace(/"/g, ""),
      };
    })
    return normalizedData.map((d: ICsvData) => {
      const { nama_sekolah, tlp, email, whatsapp } = d;
      return {
        ...(nama_sekolah && { name: nama_sekolah }),
        ...(tlp && { phone: tlp }),
        ...(email && { email }),
        ...(whatsapp && { whatsapp }),
      };
    });
  };

  return (
    <React.Fragment>
      <Title title="stageUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="stageUpload.fileInputLabel"
        apiResource={entity.STAGE.API_UPLOAD}
        resource={entity.STAGE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default StageUpload;
