import { Edit } from "react-admin";
import Form from "./StudentForm";
import EditTitle from "@src/component/EditTitle";

const StudentEdit = () => {
  return (
    <Edit title={<EditTitle />}>
      <Form />
    </Edit>
  );
};

export default StudentEdit;
