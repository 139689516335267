import { Edit, useGetRecordId, useGetOne } from 'react-admin';
import Form from './StudentReportForm';
import entity from '@src/entityResource';

const StudentReportEdit = () => {
  const recordId = useGetRecordId();
  const { data: report } = useGetOne(entity.STUDENT_REPORT.NAME, {
    id: recordId ?? 0,
  });
  const title = report?.student?.name;

  return (
    <Edit title={title}>
      <Form />
    </Edit>
  );
};

export default StudentReportEdit;
