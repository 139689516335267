import AssessmentIcon from '@mui/icons-material/Assessment';
import list from './StudentReportList';
import create from './StudentReportCreate';
import edit from './StudentReportEdit';

export default {
  list: list,
  create: create,
  edit: edit,
  icon: AssessmentIcon,
};
