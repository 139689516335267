import { TextInput, required } from "react-admin";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
} from "@src/themes/componentStyle";
import SodikSimpleForm from "@src/component/SodikSimpleForm";

const EmployeeForm = () => (
  <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <SodikSimpleForm>
        <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput
                source="nik"
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="name" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="nickname" fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="communicationPhoneNo" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="communicationEmail" validate={required()} fullWidth />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="longlat" fullWidth />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <TextInput source="address" multiline fullWidth />
            </Box>
          </Box>
      </SodikSimpleForm>
    </Grid>
  </Grid>
);

export default EmployeeForm;
