import React from "react";
import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { iFile } from "@src/component/iFile";
import PreviewFile from "@src/component/PreviewFile";
import FileViewer from "@src/component/FileViewer";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { listRowSxWithButton } from "@src/themes/componentStyle";

const SchoolGuidelineList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<iFile>();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const handleOpen = (r: any) => {
    setFile({
      title: r.stage.name,
      fileUrl: r.fileUrl,
      fileName: r.fileName
    });
    setOpen(true);
  };

  return (
    <SodikInfiniteList
      filterLabel="resources.school-guidelines.filters.search"
      uploadUrl={""}
    >
      {isSmall ? (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <SimpleList
            primaryText={(record) => (
              <>
                <div style={{display: 'flex'}}>
                  <div><strong>{record?.stage?.name}</strong></div>
                </div>
                <div style={{display: 'flex'}}>
                  <div>{record?.schoolYear?.name}</div>
                </div>
              </>
            )}
            secondaryText={(record) => (
              <div style={{ display: 'flex', marginTop: 8}}>
                <div style={{ flex: 1, border: 1, marginRight: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
                  </div>
                </div>
                <div style={{ flex: 1, border: 1, marginLeft: 1, borderRadius: 8, borderStyle: 'solid', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <EditButton />
                  </div>
                </div>
              </div>
            )}
            linkType={false}
            rowSx={listRowSxWithButton}
          />
        </>
      ) : (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <Datagrid>
            <TextField source="stage.name" />
            <TextField source="name" />
            <>
              <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
              <EditButton />
            </>
          </Datagrid>
        </>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolGuidelineList;
  