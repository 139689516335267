import { DataProvider, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import env from './environment';

const apiUrl = env.apiUrl;

const mapJsonResponse = (response: any) => ({
  data: response.rows.map((row: any) => ({ ...row })),
  total: response.count,
});

const httpClient = (url: string, options: any = {}) => {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${auth.token.access}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, perPage]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return mapJsonResponse(json);
  },

  // @ts-ignore
  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url);
    return { data: json };
  },

  getMany: async (resource, params) => {
    const query = { filter: JSON.stringify({ id: params.ids }) };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return mapJsonResponse(json);
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return mapJsonResponse(json);
  },

  // @ts-ignore
  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;

    if (params.data.file) {
      const formData = new FormData();
      formData.append('file', params.data.file.rawFile);

      for (const [key, value] of Object.entries(params.data)) {
        if (key !== 'file') {
          formData.append(key, value);
        }
      }

      const { json } = await httpClient(url, {
        method: 'PUT',
        body: formData,
      });
      return { data: json };
    }

    const { json } = await httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    });

    return { data: json };
  },

  updateMany: async (resource, params) => {
    const query = { filter: JSON.stringify({ id: params.ids }) };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  // @ts-ignore
  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    if (params.data.file) {
      const formData = new FormData();
      formData.append('file', params.data.file.rawFile);

      for (const [key, value] of Object.entries(params.data)) {
        if (key !== 'file') {
          formData.append(key, value);
        }
      }

      const { json } = await httpClient(url, {
        method: 'POST',
        body: formData,
      });
      return { data: { ...params.data, id: json.id } };
    }

    if (params.data.pdf) {
      const formData = new FormData();
      formData.append('file', params.data.pdf.rawFile);

      for (const [key, value] of Object.entries(params.data)) {
        if (key !== 'file') {
          formData.append(key, value);
        }
      }

      const { json } = await httpClient(url, {
        method: 'POST',
        body: formData,
      });
      return { data: { ...params.data, id: json.id } };
    }

    const { json } = await httpClient(url, {
      method: 'POST',
      body: JSON.stringify({ ...params.data, createdBy: 1 }),
    });
    return { data: { ...params.data, id: json.id } };
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, { method: 'DELETE' });
    return { data: json };
  },

  deleteMany: async (resource, params) => {
    const query = { filter: JSON.stringify({ id: params.ids }) };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, { method: 'DELETE' });
    return mapJsonResponse(json);
  },
};
