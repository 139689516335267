import { Datagrid, SimpleList, TextField, EditButton } from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";

const HighlightTypeList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <SodikInfiniteList
      filterLabel="resources.highlight-types.filters.search"
      uploadUrl={entity.HIGHLIGHT_TYPE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          leftAvatar={(record) => record.id}
          primaryText={(record) => record.name}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};
export default HighlightTypeList;
