import HighlightIcon from "@mui/icons-material/Highlight";
import list from "./HighlightTypeList";
import create from "./HighlightTypeCreate";
import edit from "./HighlightTypeEdit";
import upload from "./HighlightTypeUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: HighlightIcon,
};
