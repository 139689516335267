import CelebrationIcon from '@mui/icons-material/Celebration';
import list from "./GenerationList";
import create from "./GenerationCreate";
import edit from "./GenerationEdit";
import upload from "./GenerationUpload";

export default {
  list: list,
  create: create,
  edit: edit,
  upload: upload,
  icon: CelebrationIcon,
};
