import React from "react";
import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
  useLocaleState,
  useTranslate
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { iFile } from "@src/component/iFile";
import PreviewFile from "@src/component/PreviewFile";
import FileViewer from "@src/component/FileViewer";
import entity from "@src/entityResource";
import { displayFormat } from "@src/helper/dateFormat";
import { format } from "@src/helper/number";
import { listRowSx } from "@src/themes/componentStyle";
import env from "@src/environment";

const RewardList = () => {
  const intl = env.localFormat;
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<iFile>();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const handleOpen = (r: any) => {
    setFile({
      title: r.gradeName ? r.stageName + " - " + r.gradeName : r.stageName,
      fileUrl: r.fileUrl,
      fileName: r.fileName
    });
    setOpen(true);
  };

  return (
    <SodikInfiniteList
      filterLabel="resources.rewards.filters.search"
      uploadUrl={entity.REWARD.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record: any) => (
            <>
              <div style={{display: 'flex'}}>
                <div>{record?.rewardType?.name}</div>
              </div>
              <div style={{display: 'flex'}}>
                <div>{displayFormat(record.validFrom, locale)} - {displayFormat(record.validTo, locale)}</div>
              </div>
              <hr />
              <div style={{display: 'flex'}}>
                <div style={{flex: 3, textAlign: 'right'}}>{translate("resources.rewards.list.point")}</div>
                <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.point)}</strong></div>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{flex: 3, textAlign: 'right'}}>{translate("resources.rewards.list.available")}</div>
                <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.available)}</strong></div>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{flex: 3, textAlign: 'right'}}>{translate("resources.rewards.list.value")}</div>
                <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.value)}</strong></div>
              </div>
            </>
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <>
          <FileViewer open={open} file={file} handleClose={handleClose} />
          <Datagrid>
            <TextField source="rewardType.name" />
            <TextField source="id" />
            <TextField source="name" />
            <FunctionField
              label="resources.rewards.fields.point"
              render={(record: any) => format(record.point)}
            />
            <FunctionField
              label="resources.rewards.fields.value"
              render={(record: any) => format(record.value)}
            />
            <FunctionField
              label="resources.rewards.fields.limit"
              render={(record: any) => format(record.limit)}
            />
            <FunctionField
              label="resources.rewards.fields.available"
              render={(record: any) => format(record.available)}
            />
            <FunctionField
              label="Periode"
              render={(record: any) => record.validFrom && record.validTo ? `${displayFormat(record.validFrom, locale)} - ${displayFormat(record.validTo,locale)}` : ""}
            />
            <>
              <PreviewFile onOpen={handleOpen} label={translate("amip.common.view")} />
              <EditButton />
            </>
          </Datagrid>
        </>
      )}
    </SodikInfiniteList>
  );
};

export default RewardList;
